import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import {
  UserMe,
  UserInformation,
  UserDownloadable,
} from '@bamboo/core-lib/src/api/types';
import { ArrayUtils } from '@bamboo/ts-utils';

type InitialState = {
  userProfile?: UserMe;
  userByUserID: { [userID: string]: UserInformation | undefined };
  userListed: string[];
  userDownloadableByUsdoID: { [usdoID: number]: UserDownloadable | undefined };
  userDownloadableByUserID: { [userID: string]: number[] | undefined };
};

const initialData: InitialState = {
  userProfile: undefined,
  userByUserID: {},
  userListed: [],
  userDownloadableByUsdoID: {},
  userDownloadableByUserID: {},
};

let store = createStore<InitialState>(() => initialData);

export const setUserProfile = (u?: UserMe | ((u?: UserMe) => UserMe)) => {
  if (typeof u === 'function') {
    const { userProfile } = store.getState();
    store.setState({ userProfile: u(userProfile) });
    return;
  }

  store.setState({ userProfile: u });
};

export const setUser = (u: UserInformation) => {
  const { userByUserID } = store.getState();
  store.setState({
    userByUserID: {
      ...userByUserID,
      [u.userID]: u,
    },
  });
};

export const removeUser = (u: UserInformation) => {
  let userByUserID = store.getState().userByUserID;
  delete userByUserID[u.userID];
  store.setState({ userByUserID });
};

export const setUsers = (
  users: UserInformation[] | ((p: UserInformation[]) => UserInformation[])
) => {
  let list: UserInformation[] = [];
  let userByUserID = store.getState().userByUserID;
  if (Array.isArray(users)) {
    list = users;
  } else {
    const current = Object.keys(userByUserID)
      .map((userID) => userByUserID[userID])
      .filter((c) => !!c) as UserInformation[];
    list = users(current);
  }
  const userListed = list.map((c) => {
    userByUserID[c.userID] = c;
    return c.userID;
  });

  store.setState({
    userByUserID,
    userListed,
  });
};

export const setUserDownloadables = (downloadables: UserDownloadable[]) => {
  const { userDownloadableByUserID, userDownloadableByUsdoID } =
    store.getState();
  let usdoByUsdoID = { ...userDownloadableByUsdoID };
  let usdoByUserID = { ...userDownloadableByUserID };

  downloadables.forEach((d) => {
    usdoByUsdoID[d.usdoID] = d;
    usdoByUserID[d.userID] = ArrayUtils.removeDuplicates([
      ...(usdoByUserID?.[d.userID] ?? []),
      d.usdoID,
    ]);
  });

  store.setState({
    userDownloadableByUsdoID: usdoByUsdoID,
    userDownloadableByUserID: usdoByUserID,
  });
};

export const setUserDownloadable = (downloadable: UserDownloadable) => {
  const { userDownloadableByUserID, userDownloadableByUsdoID } =
    store.getState();

  store.setState({
    userDownloadableByUsdoID: {
      ...userDownloadableByUsdoID,
      [downloadable.usdoID]: downloadable,
    },
    userDownloadableByUserID: {
      [downloadable.userID]: ArrayUtils.removeDuplicates([
        ...(userDownloadableByUserID?.[downloadable.userID] ?? []),
        downloadable.usdoID,
      ]),
    },
  });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function reset(
  newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export function state() {
  return store.getState();
}

export const useUserStore = () => useStore(store, (s) => s);
