import React from 'react';
import { useGetTerm, useGetPendingTerms } from '@bamboo/core-lib/hooks/term';
import { createModal } from '@bamboo/core-lib/components/PromiseDialog';
import {
  Box,
  Link,
  Dialog,
  Button,
  Checkbox,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';
import MarkdownToHtml from '@bamboo/core-lib/components/Markdown/MarkdownToHtml';
import { navigationConfig } from '@/components/layout/navigation.config';
import { useTranslation } from 'next-i18next';
import { api } from '@bamboo/core-lib/src/api';

const DialogText = styled('h2')(() => ({
  boxSizing: 'border-box',
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  lineHeight: 1,
  color: 'rgb(53, 53, 53)',
  fontSize: '17px',
  fontWeight: 'normal',
  whiteSpace: 'break-spaces',
  textAlign: 'center',
}));

const InfiniteLoading = styled('div')(() => ({
  '&:before': {
    content: "''",
    top: 0,
    left: 0,
    position: 'absolute',
    zIndex: 2,
    height: '100%',
    background: '#f7f7f7',
    width: '100%',
    borderRadius: 8,
    opacity: '.4',
  },
  '&:after': {
    content: "''",
    height: '100%',
    width: '100%',
    borderRadius: 8,
    background: 'url(/assets/Infinity-0.9s-201px.gif)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: '#f7f7f7',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
    backgroundSize: 80,
    opacity: '.44',
  },
}));

const CloseContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  lineHeight: 1,
  color: 'rgb(73, 81, 87)',
  fontSize: '12px',
  marginTop: '0px',
  padding: '20px 20px 0',
  opacity: 1,
}));

const BpIcon = styled('span')(() => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  backgroundColor: '#e7e7e7',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#e7e7e7;',
  },
  'input:disabled ~ &': {
    background: '#e7e7e7;',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#26bebf',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#26bebf',
  },
});

const StyledButton = styled(Button)(() => ({
  backgroundColor: 'rgb(25, 186, 187)',
  borderRadius: '60px',
  marginTop: '16px',
  textTransform: 'none',
  padding: '10px',
  '&: hover': {
    backgroundColor: 'rgb(25, 186, 187)',
  },
  '&: disabled': {
    backgroundColor: '#9d9494',
    color: 'white',
  },
}));

const [rendererTerm, promiseTerm] = createModal(
  ({
    slug,
    open,
    close,
  }: {
    slug: string;
    open: boolean;
    close: () => void;
  }) => {
    const [loading, term] = useGetTerm(slug);
    const { t } = useTranslation(navigationConfig.i18nNamespaces);

    const onClose = React.useCallback(() => {
      close();
    }, [close]);

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onClose}
        PaperProps={{ style: { borderRadius: '25px', minHeight: '80vh' } }}
      >
        <CloseContainer>
          <Button
            onClick={onClose}
            color="inherit"
            style={{
              display: 'inline-block',
              letterSpacing: '1px',
              fontSize: 'inherit',
              fontWeight: 400,
              fontStyle: 'normal',
              lineHeight: 1,
              textTransform: 'none',
              marginTop: '0px',
              marginBottom: '15px',
            }}
          >
            {t('common:actions.close')}
          </Button>
        </CloseContainer>
        <DialogContent>
          <HiddenComponent hidden={!loading}>
            <InfiniteLoading />
          </HiddenComponent>
          <HiddenComponent hidden={loading}>
            <Box
              sx={{
                boxSizing: 'border-box',
                width: '100%',
                display: 'flex',
                '& h2': {
                  padding: '0px',
                  margin: '0px',
                  lineHeight: 1,
                  color: 'rgb(51, 50, 50)',
                  fontSize: '28px',
                  fontWeight: 700,
                },
                '& p': {
                  color: '#727272',
                  fontSize: '13px',
                  fontWeight: 400,
                  width: '100%',
                  textAlign: 'justify',
                  marginBottom: '0.9rem',
                },
              }}
            >
              <MarkdownToHtml enableLinks markdown={term?.description ?? ''} />
            </Box>
          </HiddenComponent>
        </DialogContent>
      </Dialog>
    );
  }
);

export const PendingTermDialog = () => {
  const [pending] = useGetPendingTerms();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState<number[]>([]);
  const terms = pending?.terms ?? [];
  const { t } = useTranslation(navigationConfig.i18nNamespaces);

  const setTerm = React.useCallback((termID: number) => {
    setTermsAccepted((list) => {
      const index = list.indexOf(termID);
      if (index === -1) {
        return Array.from(new Set([...list, termID]));
      }
      list.splice(index, 1);
      return Array.from(new Set([...list]));
    });
  }, []);

  const openTerm = React.useCallback((slug: string) => {
    promiseTerm({ slug });
  }, []);

  const onAccept = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (termsAccepted.length !== terms.length) {
        return;
      }
      setLoading(true);
      Promise.all(termsAccepted.map((termID) => api.termAccept({ termID })))
        .then(() => {
          setOpen(false);
        })
        .catch(console.warn)
        .finally(() => setLoading(false));
    },
    [terms, termsAccepted]
  );

  React.useEffect(() => {
    const terms = pending?.terms ?? [];
    setOpen(terms.length > 0);
  }, [pending]);

  return (
    <>
      <Dialog open={open} PaperProps={{ style: { borderRadius: '25px' } }}>
        <DialogTitle sx={(theme) => ({ padding: theme.spacing(2, 4) })}>
          {t('navigation:terms.terms_and_conditions')}
        </DialogTitle>
        <DialogContent sx={(theme) => ({ padding: theme.spacing(3, 4) })}>
          <DialogText sx={(theme) => ({ marginTop: theme.spacing() })}>
            {t('navigation:terms.welcome')}
          </DialogText>
          <DialogText
            sx={(theme) => ({
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(3),
            })}
          >
            {t('navigation:terms.accept_the_terms_below')}
          </DialogText>
          <Typography> {t('navigation:terms.read_and_accept')} </Typography>
          {terms.map((term) => (
            <Typography
              variant="subtitle2"
              color="GrayText"
              sx={{ fontWeight: 400 }}
            >
              <Checkbox
                color="default"
                checked={termsAccepted.indexOf(term.termID) !== -1}
                onChange={() => setTerm(term.termID)}
                icon={<BpIcon />}
                checkedIcon={<BpCheckedIcon />}
                inputProps={{ 'aria-label': 'Checkbox demo' }}
                sx={{
                  paddingLeft: 0,
                  paddingRight: (theme) => theme.spacing(0.5),
                }}
              />
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#26bebf',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openTerm(term.slug);
                }}
              >
                {term?.title}
              </Link>
            </Typography>
          ))}
          <StyledButton
            fullWidth
            disableElevation
            variant="contained"
            color="secondary"
            onClick={onAccept}
            disabled={loading}
            style={{ fontWeight: 700 }}
            endIcon={loading ? <CircularProgress size={24} /> : undefined}
          >
            {t('navigation:terms.accept_terms')}
          </StyledButton>
        </DialogContent>
      </Dialog>
      {rendererTerm}
    </>
  );
};

export default PendingTermDialog;
