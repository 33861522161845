import { mediaApi, api, adminApi, schemas } from '../clients';
import { setDownloadableClips } from '../../repo/clips';
import { getToken, state, setMediaToken } from '../../repo/session';
import { z } from 'zod';
import { UploadedMedia } from '../types';
import { jwt_payload } from '../jwt';

export type TaskInformation = Awaited<
  ReturnType<typeof mediaApi.mediaMonitoringTask>
>['data']['item'];

export enum TaskState {
  Active = 'active',
  Pending = 'pending',
  Scheduled = 'scheduled',
  Retry = 'retry',
  Archived = 'archived',
  Completed = 'completed',
  Aggregating = 'aggregating',
}

export enum ProgressStatus {
  Process = 'process',
  GeneratingThumbnail = 'generatingThumbnail',
  GeneratingPreview = 'generatingPreview',
  GeneratingHDVideo = 'generatingHDVideo',
  GeneratingWatermark = 'generatingWatermark',
  SendingThumbnailToS3 = 'sendingThumbnailToS3',
  SendingPreviewToS3 = 'sendingPreviewToS3',
  SendingWatermarkToS3 = 'sendingWatermarkToS3',
  SendingVideoToS3 = 'sendingVideoToS3',
  Done = 'done',
  Failure = 'failure',
}

export function getStatusWeight(s: string): number {
  const l = {
    [ProgressStatus.Process]: 5,
    [ProgressStatus.GeneratingThumbnail]: 15,
    [ProgressStatus.SendingThumbnailToS3]: 25,
    [ProgressStatus.SendingVideoToS3]: 30,
    [ProgressStatus.Done]: 0,
  } as any;
  return l[s] || 0;
}

export function getStatusLabel(s: string): string {
  const l = {
    [ProgressStatus.Process]: 'Processando video...',
    [ProgressStatus.GeneratingThumbnail]: 'Gerando miniatura...',
    [ProgressStatus.SendingThumbnailToS3]: 'Salvando miniatura...',
    [ProgressStatus.SendingVideoToS3]: 'Salvando vídeo...',
    [ProgressStatus.GeneratingPreview]: 'Gerando pré-visualização...',
    [ProgressStatus.GeneratingHDVideo]: 'Gerando vídeo HD...',
    [ProgressStatus.GeneratingWatermark]: "Gerando marca d'água...",
    [ProgressStatus.SendingPreviewToS3]: 'Salvando pré-visualização...',
    [ProgressStatus.SendingWatermarkToS3]: "Salvando marca d'água...",
    [ProgressStatus.Done]: 'Concluído!',
    [ProgressStatus.Failure]: 'Falhou!',
  } as any;
  return l[s] || s;
}

export function videoUpload(
  file: File,
  token: string,
  cb: (e: ProgressEvent) => void
): Promise<TaskInformation> {
  return new Promise<TaskInformation>(async (resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const data = new FormData();

    data.append('file', file);

    xhr.open('POST', `${mediaApi.baseURL}/api/video/upload`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.upload.addEventListener('progress', cb);

    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        let resp: any;
        try {
          resp = JSON.parse(xhr.response);
        } catch (e) {
          reject(e);
          return;
        }
        resolve(resp?.data?.item);
      } else {
        reject(`HTTP error ${xhr.status}: ${xhr.statusText}`);
      }
    });
    xhr.addEventListener('error', (e) => {
      reject('Network error');
    });
    xhr.addEventListener('abort', (e) => {
      reject('Upload cancelled');
    });
    xhr.send(data);
  });
}

export type UploadProgress = {
  status?: string;
  progressStatus?: string[];
} & Partial<ProgressEvent>;

export async function uploadFileAndMonitorProgress(
  file: File,
  token: string,
  cb: (e: UploadProgress) => void
): Promise<TaskInformation> {
  return new Promise<TaskInformation>((resolve, reject) => {
    let errorCounter = 0;
    videoUpload(file, token, cb)
      .then((task) => {
        cb({ status: task.status, progressStatus: task.progress });
        const t = setInterval(() => {
          mediaApi
            .mediaMonitoringTask({ params: { taskID: task.taskID } })
            .then((r) => {
              const progressInfo = {
                status: r.data.item.status,
                progressStatus: r.data.item.progress,
              } as UploadProgress;
              cb(progressInfo);
              if (progressInfo.status === TaskState.Completed) {
                resolve(r.data.item);
                clearInterval(t);
              }
              if (progressInfo.status === TaskState.Archived) {
                reject('task archived');
                clearInterval(t);
              }
            })
            .catch((e) => {
              const message: string =
                e?.response?.data?.error?.message ?? e?.message ?? e ?? '';
              const authenticationError =
                message?.indexOf?.('Authorization') !== -1 ||
                message?.indexOf?.('malformed jwt') !== -1;
              if (errorCounter < 6 && authenticationError) {
                errorCounter++;
                console.warn(e);
                return;
              }
              reject(e);
              clearInterval(t);
            });
        }, 5000);
      })
      .catch(reject);
  });
}

export type UploadedMediaCreate = z.infer<
  typeof schemas.uploadedMediaCreate_Body
>;

let tokenMediaPromise: Promise<string> | null = null;
export async function getTokenMedia() {
  const t2 = tokenMediaPromise;
  tokenMediaPromise = new Promise<string>(async (resolve, reject) => {
    const jwt = await t2;
    if (
      !jwt ||
      jwt_payload(jwt)?.exp <= Math.floor(new Date().getTime() / 1000) - 10
    ) {
      let nJWT = '';
      try {
        nJWT = await api.uploadedMediaAuth({}).then((r) => r.data.item.jwt);
      } catch (e) {
        reject(e);
      }
      resolve(nJWT);
    } else {
      resolve(jwt);
    }
  });

  return tokenMediaPromise;
}

export async function fileUploadFlow(
  file: File,
  cb: (e: UploadProgress) => void
): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    let token = '';
    try {
      token = await getTokenMedia();
    } catch (e) {
      reject(e);
      return;
    }
    await uploadFileAndMonitorProgress(file, token, cb)
      .then(async ({ videoInfo }) => {
        const data: UploadedMediaCreate = {
          hdURL: videoInfo?.fullHDVideoURL ?? '',
          ultraHd4kURL: videoInfo?.ultraHD4kVideoURL ?? '',
          thumbnailImageURL: videoInfo?.thumbnailURL ?? '',
          thumbnailVideoURL: videoInfo?.previewURL ?? '',
          watermarkPreviewURL: videoInfo?.watermarkVideoURL ?? '',
          information: {
            codecName: videoInfo.codecName,
            duration: videoInfo.duration,
            framerate: videoInfo.framerate,
            height: videoInfo.height,
            width: videoInfo.width,
          },
        };
        await api
          .uploadedMediaCreate(data)
          .then(() => resolve())
          .catch(reject);
      })
      .catch(reject);
  });
}

async function getAdminTokenMedia() {
  const t2 = tokenMediaPromise;
  tokenMediaPromise = new Promise<string>(async (resolve, reject) => {
    const jwt = await t2;
    if (
      !jwt ||
      jwt_payload(jwt)?.exp <= Math.floor(new Date().getTime() / 1000) - 10
    ) {
      let nJWT = '';
      try {
        nJWT = await adminApi.mediaAuth({}).then((r) => r.data.item.jwt);
      } catch (e) {
        reject(e);
      }
      resolve(nJWT);
    } else {
      resolve(jwt);
    }
  });

  return tokenMediaPromise;
}

export async function adminFileUploadFlow(
  file: File,
  cb: (e: UploadProgress) => void
): Promise<UploadedMedia> {
  return new Promise<UploadedMedia>(async (resolve, reject) => {
    let token = '';
    try {
      token = await getAdminTokenMedia();
    } catch (e) {
      reject(e);
      return;
    }
    await uploadFileAndMonitorProgress(file, token, cb)
      .then(async ({ videoInfo }) => {
        const data: UploadedMediaCreate = {
          hdURL: videoInfo?.fullHDVideoURL ?? '',
          ultraHd4kURL: videoInfo?.ultraHD4kVideoURL ?? '',
          thumbnailImageURL: videoInfo?.thumbnailURL ?? '',
          thumbnailVideoURL: videoInfo?.previewURL ?? '',
          watermarkPreviewURL: videoInfo?.watermarkVideoURL ?? '',
          information: {
            codecName: videoInfo.codecName,
            duration: videoInfo.duration,
            framerate: videoInfo.framerate,
            height: videoInfo.height,
            width: videoInfo.width,
          },
        };
        await adminApi
          .uploadedMediaCreate(data)
          .then((r) => {
            resolve(r.data.item);
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

export type DownloadURL = Awaited<
  ReturnType<typeof mediaApi.mediaGetDownloadUrl>
>['data']['item'];

export function getVideoDownloadURL(
  token: string,
  url: string,
  usdoID?: number
): Promise<DownloadURL> {
  return new Promise<DownloadURL>(async (resolve, reject) => {
    const resp = await api
      .uploadedMediaAuth(
        { mediaURL: url, usdoID },
        { headers: { Authorization: token } }
      )
      .catch(reject);

    if (!resp) {
      reject('not authorized');
      return;
    }
    if (!!usdoID) {
      setDownloadableClips((dc) => {
        const list = [...dc];
        const index = list.findIndex((d) => d.usdoID === usdoID);
        list[index] = {
          ...list[index],
          downloadCount: list[index].downloadCount + 1,
        };
        return list;
      });
    }
    const auth = resp.data.item.jwt;
    mediaApi
      .mediaGetDownloadUrl({
        queries: {
          file: url,
          expirationInMinutes: '5',
        },
        headers: { Authorization: `Bearer ${auth}` },
      })
      .then((r) => resolve(r.data.item))
      .catch(reject);
  });
}

export function getAdminVideoDownloadURL(
  url: string,
  expirationInMinutes?: string
): Promise<DownloadURL> {
  return new Promise<DownloadURL>(async (resolve, reject) => {
    const resp = await adminApi.mediaAuth({ mediaURL: url }).catch(reject);
    if (!resp) {
      reject('not authorized');
      return;
    }
    const auth = resp.data.item.jwt;
    mediaApi
      .mediaGetDownloadUrl({
        queries: {
          file: url,
          expirationInMinutes: expirationInMinutes ?? '5',
        },
        headers: { Authorization: `Bearer ${auth}` },
      })
      .then((r) => resolve(r.data.item))
      .catch(reject);
  });
}

export function getEcommerceVideoDownloadURL(
  url: string,
  expirationInMinutes?: string
): Promise<DownloadURL> {
  return new Promise<DownloadURL>(async (resolve, reject) => {
    const resp = await api.uploadedMediaAuth({ mediaURL: url }).catch(reject);
    if (!resp) {
      reject('not authorized');
      return;
    }
    const auth = resp.data.item.jwt;
    mediaApi
      .mediaGetDownloadUrl({
        queries: {
          file: url,
          expirationInMinutes: expirationInMinutes ?? '5',
        },
        headers: { Authorization: `Bearer ${auth}` },
      })
      .then((r) => resolve(r.data.item))
      .catch(reject);
  });
}

export function postSingleMediaFile(
  file: File,
  token: string
): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {
    const data = new FormData();
    data.append('file', file);
    mediaApi.axios
      .post('/api/public-media/upload', data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((r) => {
        const { url } = r.data.data.item;
        resolve(url);
      })
      .catch(reject);
  });
}

export function publicFileUpload(file: File): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {
    const { media, jwt } = state();
    let tokenMedia = media;
    try {
      if (jwt) {
        const resp = await api.uploadedMediaAuth(
          {},
          { headers: { Authorization: `Bearer ${jwt}` } }
        );
        tokenMedia = resp.data.item.jwt;
      } else {
        const resp = await api.mideaServerPublicAuth({});
        tokenMedia = resp.data.item.jwt;
      }
    } catch (e) {
      reject(e);
      return;
    }
    const data = new FormData();
    data.append('file', file);
    mediaApi.axios
      .post('/api/public-media/upload', data, {
        headers: { Authorization: `Bearer ${tokenMedia}` },
      })
      .then((r) => {
        const { url } = r.data.data.item;
        resolve(url);
      })
      .catch(reject);
  });
}
