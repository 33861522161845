import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 317.888 317.888"
    {...props}
  >
    <path d="m199.566 8.701 83.959 83.953h-55.256c-15.828 0-28.703-12.877-28.703-28.709V8.701zM222.541 289.179c0 15.829-12.893 28.709-28.721 28.709H54.388c-15.834 0-28.709-12.88-28.709-28.709V80.023c0-15.834 12.875-28.715 28.709-28.715H117.58v63.942c0 22.614 18.393 41.007 41.007 41.007h63.954v132.922z" />
    <path d="M213.845 143.959h-55.259c-15.826 0-28.709-12.874-28.709-28.709V60.003l83.968 83.956z" />
    <path d="M263.511 266.577h-28.673V150.108c0-.141-.078-.27-.09-.411a6.075 6.075 0 0 0-.307-1.531c-.072-.204-.132-.405-.222-.616-.295-.646-.667-1.261-1.184-1.783v-.006c-.012 0-.018-.006-.018-.006L128.076 40.812c-.535-.528-1.156-.903-1.813-1.204-.171-.082-.357-.132-.535-.192a5.756 5.756 0 0 0-1.624-.324c-.129-.015-.246-.081-.381-.081h-28.37V28.715C95.353 12.884 108.239 0 124.068 0h63.188v63.945c0 22.614 18.398 41.007 41.013 41.007h63.939v132.922c.012 15.828-12.869 28.703-28.697 28.703z" />
  </svg>
);
export default SvgComponent;
