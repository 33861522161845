import SocialLogin from '@/components/layout/SocialLogin';
import { authConfig } from '@/features/auth/auth.config';
import { LoginForm } from '@/features/auth/components/LoginForm';
import { OnboardingForm } from '@/features/auth/components/OnboardingForm';
import { api } from '@bamboo/core-lib/api';
import type { LoginFormValues } from '@bamboo/core-lib/components/Auth/LoginForm';
import type { OnboardingFormValues } from '@bamboo/core-lib/components/Auth/OnboardingForm';
import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';
import { useInlineNotification } from '@bamboo/core-lib/components/Notification';
import { ApplicationID } from '@bamboo/ts-utils';
import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useLoginModalStore } from './store';
import {
  CloseContainer,
  FooterContainer,
  ForgetPasswordLink,
  FormDescription,
  FormLoginContainer,
  FormTitle,
  HeaderContainer,
  LoginModalContainer,
  NavigateThrough,
  NavigateThroughContainer,
  Separator,
  SocialContainer,
} from './styles';
import { eventCreateAccount } from '@/lib/seo/GAEvents';

export enum SubmittedType {
  login = 'login',
  onboarding = 'onboarding',
}

type LoginModalProps = {
  isLoginPage?: boolean;
};

export const LoginModal = ({ isLoginPage = false }: LoginModalProps) => {
  const { setIsOpen, startWith, callback, setCallback } = useLoginModalStore();
  const { t } = useTranslation(authConfig.i18nNamespaces);

  const router = useRouter();
  const [page, setPage] = useState<string>(() => startWith ?? 'login');

  const [Notification, notify] = useInlineNotification();
  const [NotificationSocial, _] = useInlineNotification();
  const [submittedForm, setSubmittedForm] = useState<SubmittedType>();

  function closeModal() {
    setCallback(undefined);
    setIsOpen(false);
  }

  const handleSubmit = useCallback(async (formValues: LoginFormValues) => {
    const { username, password, rememberme } = formValues;
    setSubmittedForm(SubmittedType.login);
    await api
      .post(
        '/api/auth/signin',
        {
          applID: ApplicationID.ecommerce,
          email: username,
          password,
          rememberMe: rememberme,
          deviceID: '',
          deviceName: '',
          ip: '',
          location: '',
          coord: '',
        },
        { withCredentials: true }
      )
      .then(() => {
        if (isLoginPage) {
          const redirect = router.query['redirect'] as string;
          router.push(redirect ?? '/home');
          return;
        }
        if (!callback) {
          router.reload();
          return;
        }
        callback?.();
        closeModal();
      })
      .catch((e) => {
        let messagem = t('navigation:authForm.generic_login_error');
        const err = e?.response?.data?.error?.message ?? e?.message ?? e;
        if (err?.indexOf?.('No user with this email') > -1) {
          messagem = t('navigation:authForm.user_doesnt_exist');
        }

        notify({
          message: messagem,
          type: 'error',
          temporary: true,
        });
      });
  }, []);

  const handleCreate = useCallback(async (formValues: OnboardingFormValues) => {
    const { userFirstName, userLastName, password, email } = formValues;
    setSubmittedForm(SubmittedType.onboarding);

    await api
      .authCreateAccount({
        name: userFirstName + ' ' + userLastName,
        applID: ApplicationID.ecommerce,
        email: email,
        password,
        deviceID: '',
        deviceName: '',
        ip: '',
        location: '',
        coord: '',
      })
      .then(() => {
        eventCreateAccount(email);
        router.push('/plans');
        closeModal();
      })
      .catch((e) => {
        let message = t('navigation:authForm.generic_onboarding_error');
        const err = e?.response?.data?.error?.message ?? e?.message ?? e;
        if (err?.indexOf?.('Already exists user') > -1) {
          message = t('navigation:authForm.user_already_exists');
        }

        notify({
          message: message,
          type: 'error',
          temporary: true,
        });
      });
  }, []);

  return (
    <LoginModalContainer
      container
      className={isLoginPage ? 'is-login-page' : ''}
    >
      {page === 'login' && (
        <FormLoginContainer className={isLoginPage ? 'is-login-page' : ''}>
          <HeaderContainer>
            <FormTitle>Que bom ter você aqui!</FormTitle>
            <FormDescription>Acesse sua conta e comece a criar</FormDescription>

            {!isLoginPage && (
              <CloseContainer>
                <IconButton onClick={() => closeModal()}>
                  <Close></Close>
                </IconButton>
              </CloseContainer>
            )}
          </HeaderContainer>

          <SocialContainer>
            <SocialLogin
              labels={{
                google: ['Fazer login com o ', 'Google'],
              }}
              urls={{
                google: `${process.env.OAUTH_LOGIN_URL}&path=${router.asPath}`!,
              }}
            />
            <NotificationSocial />
          </SocialContainer>

          <Separator />

          <LoginForm onSubmit={handleSubmit}>
            <HiddenComponent hidden={submittedForm !== SubmittedType.login}>
              <Box sx={{ mt: 3 }}>
                <Notification />
              </Box>
            </HiddenComponent>
          </LoginForm>

          <FooterContainer>
            <ForgetPasswordLink href={'/auth/password-recover'}>
              Esqueci minha senha
            </ForgetPasswordLink>

            <NavigateThroughContainer>
              <div>Não tem uma conta?</div>
              <NavigateThrough onClick={() => setPage('register')}>
                Cadastre-se
              </NavigateThrough>
            </NavigateThroughContainer>
          </FooterContainer>
        </FormLoginContainer>
      )}

      {page === 'register' && (
        <FormLoginContainer className={isLoginPage ? 'is-login-page' : ''}>
          <HeaderContainer>
            <FormTitle>Crie sua conta</FormTitle>
            <FormDescription>
              Comece agora e descubra um Brasil inteiro em imagens
            </FormDescription>

            {!isLoginPage && (
              <CloseContainer>
                <IconButton onClick={() => closeModal()}>
                  <Close></Close>
                </IconButton>
              </CloseContainer>
            )}
          </HeaderContainer>

          <OnboardingForm onSubmit={handleCreate}>
            <HiddenComponent
              hidden={submittedForm !== SubmittedType.onboarding}
            >
              <Box sx={{ mt: 3 }}>
                <Notification />
              </Box>
            </HiddenComponent>
          </OnboardingForm>

          <FooterContainer>
            <NavigateThroughContainer>
              Já tem uma conta?
              <NavigateThrough onClick={() => setPage('login')}>
                Entrar
              </NavigateThrough>
            </NavigateThroughContainer>
          </FooterContainer>
        </FormLoginContainer>
      )}
    </LoginModalContainer>
  );
};
