import React from 'react';
import { getUserMe } from '@bamboo/core-lib/src/api/flows/user';
import { useSessionStore } from '@bamboo/core-lib/src/repo/session';

export const useGetUserMe = () => {
  const { jwt } = useSessionStore();

  React.useEffect(() => {
    if (jwt) {
      getUserMe().catch(console.warn);
    }
  }, [jwt]);
};
