import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const endpoints = makeApi([
  {
    method: "get",
    path: "/api/media/download-link",
    alias: "mediaGetDownloadUrl",
    description: `get signed url by file`,
    requestFormat: "json",
    parameters: [
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
      {
        name: "file",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "expirationInMinutes",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({ expiresAt: z.string(), url: z.string() })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/monitoring/task/:taskID",
    alias: "mediaMonitoringTask",
    description: `get task information by ID`,
    requestFormat: "json",
    parameters: [
      {
        name: "taskID",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                progress: z.array(z.string()),
                status: z.string(),
                taskID: z.string(),
                videoInfo: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    fullHDVideoURL: z.string().nullish(),
                    height: z.number(),
                    previewURL: z.string().nullish(),
                    thumbnailURL: z.string().nullish(),
                    ultraHD4kVideoURL: z.string().nullish(),
                    watermarkVideoURL: z.string().nullish(),
                    width: z.number(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/private-media/upload",
    alias: "privateMediaUpload",
    description: `Save one private media on the server`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ file: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ url: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/public-media/upload",
    alias: "publicMediaUpload",
    description: `Save one public media on the server`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ url: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/video/generate-hd-files",
    alias: "videoGenerateHDfiles",
    description: `Create a task to generate hd files derived from the original file`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ videoPath: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                progress: z.array(z.string()),
                status: z.string(),
                taskID: z.string(),
                videoInfo: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    fullHDVideoURL: z.string().nullish(),
                    height: z.number(),
                    previewURL: z.string().nullish(),
                    thumbnailURL: z.string().nullish(),
                    ultraHD4kVideoURL: z.string().nullish(),
                    watermarkVideoURL: z.string().nullish(),
                    width: z.number(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/video/generate-preview",
    alias: "videoGeneratePreview",
    description: `Create a task to generate a video preview and send it to the server`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({ videoPath: z.string() }).passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                progress: z.array(z.string()),
                status: z.string(),
                taskID: z.string(),
                videoInfo: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    fullHDVideoURL: z.string().nullish(),
                    height: z.number(),
                    previewURL: z.string().nullish(),
                    thumbnailURL: z.string().nullish(),
                    ultraHD4kVideoURL: z.string().nullish(),
                    watermarkVideoURL: z.string().nullish(),
                    width: z.number(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: "post",
    path: "/api/video/upload",
    alias: "videoUpload",
    description: `Create a task to save a video on the server`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        description: `Request data`,
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "context",
        type: "Query",
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                progress: z.array(z.string()),
                status: z.string(),
                taskID: z.string(),
                videoInfo: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    fullHDVideoURL: z.string().nullish(),
                    height: z.number(),
                    previewURL: z.string().nullish(),
                    thumbnailURL: z.string().nullish(),
                    ultraHD4kVideoURL: z.string().nullish(),
                    watermarkVideoURL: z.string().nullish(),
                    width: z.number(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
