import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import {
  ProductReview,
  FilmmakerProduct,
  AdminProductListing,
  UploadedMedia,
} from '@bamboo/core-lib/src/api/types';

type InitialState = {
  filmProductsListed: string[];
  admProductsListed: string[];
  admUploadedListed: string[];
  filmmakerProductsByID: { [upmeID: string]: FilmmakerProduct | undefined };
  adminProductByID: { [prodID: string]: AdminProductListing | undefined };
  adminProductReviewByID: { [prodID: string]: ProductReview | undefined };
  uploadedMediaByID: { [upmeID: string]: UploadedMedia | undefined };
  adminUploadedProductByID: {
    [upmeID: string]: AdminProductListing | undefined;
  };
};

const initialData: InitialState = {
  filmProductsListed: [],
  filmmakerProductsByID: {},
  admProductsListed: [],
  adminProductByID: {},
  adminProductReviewByID: {},
  uploadedMediaByID: {},
  admUploadedListed: [],
  adminUploadedProductByID: {},
};

let store = createStore<InitialState>(() => initialData);

export const setFilmmakerProducts = (
  fp: FilmmakerProduct[] | ((p: FilmmakerProduct[]) => FilmmakerProduct[])
) => {
  let list: FilmmakerProduct[] = [];
  let proByID = store.getState().filmmakerProductsByID;
  if (Array.isArray(fp)) {
    list = fp;
  } else {
    const current = Object.keys(proByID)
      .map((upmeID) => proByID[upmeID])
      .filter((c) => !!c) as FilmmakerProduct[];
    list = fp(current);
  }

  const filmProductsListed = list.map((c) => {
    proByID[c.product.upmeID] = c;
    return c.product.upmeID;
  });

  store.setState({ filmmakerProductsByID: proByID, filmProductsListed });
};

export const setFilmmakerProduct = (fp: FilmmakerProduct) => {
  const { filmmakerProductsByID } = store.getState();
  store.setState({
    filmmakerProductsByID: {
      ...filmmakerProductsByID,
      [fp.product.upmeID]: fp,
    },
  });
};

export const setAdminProduct = (p: AdminProductListing) => {
  const { adminProductByID } = store.getState();
  store.setState({
    adminProductByID: {
      ...adminProductByID,
      [p.product?.prodID ?? '']: p,
    },
  });
};

export const setAdminProducts = (
  pro:
    | AdminProductListing[]
    | ((p: AdminProductListing[]) => AdminProductListing[])
) => {
  let list: AdminProductListing[] = [];
  let admproByID = store.getState().adminProductByID;

  if (Array.isArray(pro)) {
    list = pro;
    admproByID = {};
  } else {
    const current = Object.keys(admproByID)
      .map((prodID) => admproByID[prodID])
      .filter((c) => !!c) as AdminProductListing[];
    list = pro(current);
  }

  const admProductsListed = list.map((c) => {
    admproByID[c.product.prodID ?? ''] = c;
    return c.product.prodID ?? '';
  });

  store.setState({
    adminProductByID: admproByID,
    admProductsListed,
  });
};

export const removeAdminProduct = (prodID: string) => {
  let adminProductByID = store.getState().adminProductByID;
  delete adminProductByID[prodID];
  store.setState({
    adminProductByID: adminProductByID,
  });
};

export const setAdminProductUploaded = (p: AdminProductListing) => {
  const { adminUploadedProductByID } = store.getState();
  store.setState({
    adminUploadedProductByID: {
      ...adminUploadedProductByID,
      [p.product?.upmeID ?? '']: p,
    },
  });
};

export const setAdminProductsUploaded = (
  pro:
    | AdminProductListing[]
    | ((p: AdminProductListing[]) => AdminProductListing[])
) => {
  let list: AdminProductListing[] = [];
  let admproByID = store.getState().adminUploadedProductByID;
  if (Array.isArray(pro)) {
    list = pro;
    admproByID = {};
  } else {
    const current = Object.keys(admproByID)
      .map((upmeID) => admproByID[upmeID])
      .filter((c) => !!c) as AdminProductListing[];
    list = pro(current);
  }

  const admUploadedListed = list.map((p) => {
    admproByID[p.product?.upmeID ?? ''] = p;
    return p.product?.upmeID ?? '';
  });

  store.setState({
    adminUploadedProductByID: admproByID,
    admUploadedListed,
  });
};

export const removeAdminProductUploaded = (upmeID: string) => {
  let adminUploadedProductByID = store.getState().adminUploadedProductByID;
  delete adminUploadedProductByID[upmeID];
  store.setState({
    adminUploadedProductByID: adminUploadedProductByID,
  });
};

export const setProductReview = (p: ProductReview) => {
  const { adminProductReviewByID } = store.getState();
  store.setState({
    adminProductReviewByID: {
      ...adminProductReviewByID,
      [p?.prodID ?? '']: p,
    },
  });
};

export const setProductReviews = (
  pro: ProductReview[] | ((p: ProductReview[]) => ProductReview[])
) => {
  let list: ProductReview[] = [];
  let reviewByID = store.getState().adminProductReviewByID;
  if (Array.isArray(pro)) {
    list = pro;
  } else {
    const current = Object.keys(reviewByID)
      .map((prodID) => reviewByID[prodID])
      .filter((c) => !!c) as ProductReview[];
    list = pro(current);
  }

  list.forEach((p) => {
    reviewByID[p.prodID ?? ''] = p;
  });

  store.setState({
    adminProductReviewByID: reviewByID,
  });
};

export const removeProductReview = (prodID: string) => {
  let aprByID = store.getState().adminProductReviewByID;
  delete aprByID[prodID];
  store.setState({
    adminProductReviewByID: aprByID,
  });
};

export const setUploadedMedia = (u: UploadedMedia) => {
  const { uploadedMediaByID } = store.getState();
  store.setState({
    uploadedMediaByID: {
      ...uploadedMediaByID,
      [u?.upmeID ?? '']: u,
    },
  });
};

export const setUploadedMedias = (ups: UploadedMedia[]) => {
  const { uploadedMediaByID } = store.getState();
  let upByID = { ...uploadedMediaByID };
  ups.forEach((u) => {
    upByID[u?.upmeID ?? ''] = u;
  });

  store.setState({
    uploadedMediaByID: upByID,
  });
};

export const removeUploadedMedia = (upmeID: string) => {
  let upByID = store.getState().uploadedMediaByID;
  let admUpByID = store.getState().adminUploadedProductByID;
  delete upByID[upmeID];
  delete admUpByID[upmeID];
  store.setState({
    uploadedMediaByID: upByID,
    adminUploadedProductByID: admUpByID,
  });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function state() {
  return store.getState();
}

export function reset(
  newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export const useProducts = () => useStore(store, (s) => s);
