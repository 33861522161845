import api from '@bamboo/core-lib/api';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import { useAsyncMemo } from '@bamboo/core-lib/hooks/use-async-memo';

const Analytics = () => {
  const fingerprint = useAsyncMemo(getCurrentBrowserFingerPrint, []);
  const router = useRouter();

  function isPathValid(path: string) {
    const validPaths = ['/filmmaker/', '/product/'];
    for (const validPath of validPaths) {
      if (path.includes(validPath)) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (!fingerprint) {
      return;
    }
    if (typeof window !== 'undefined') {
      if (!isPathValid(router.asPath)) return;
      api.analyticsPageview({
        path: router.asPath,
        fingerprint: String(fingerprint),
      });
    }
    const handleRouteChange = (url: string) => {
      if (!isPathValid(url)) return;
      api.analyticsPageview({
        path: url,
        fingerprint: String(fingerprint),
      });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, fingerprint]);

  return null;
};

export default Analytics;
