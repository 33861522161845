import Icons from '@bamboo/core-lib/components/Icons';
import { styled } from '@mui/material/styles';

const Container = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  boxSizing: 'border-box',
  margin: '0',
  padding: '14px 20px 14px 14px',
  gap: '10px',

  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: 600,
  color: '#544F4F',
  border: '1px solid #544F4F59',
  borderRadius: '32px',

  width: '100%',
  maxWidth: '100%',
  cursor: 'pointer',
  height: '52px',

  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',

  '&:hover': {
    backgroundColor: '#544F4F1F',
    border: '1px solid #544F4F',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
}));

const StyledIcon = styled(Icons.Google)(() => ({
  boxSizing: 'border-box',
  height: '24px',
  width: '24px',
}));

type SocialLoginProps = {
  labels: { google: string[] };
  urls: { google: string };
};

function SocialLogin({ labels, urls }: SocialLoginProps) {
  return (
    <GoogleSignin url={urls.google}>
      {labels.google?.[0] ?? ''} {labels.google?.[1] ?? 'Google'}
    </GoogleSignin>
  );
}

export default SocialLogin;

function GoogleSignin({
  children,
  url,
}: {
  children: React.ReactNode;
  url: string;
}) {
  return (
    <>
      <Container href={url} rel="nofollow">
        <StyledIcon />
        {children}
      </Container>
    </>
  );
}
