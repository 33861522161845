import React from 'react';
import { remark } from 'remark';
import html from 'remark-html';
import gfm from 'remark-gfm';
import { Theme, useTheme } from '@mui/material/styles';
import MarkdownTheme from './MarkdownTheme';
import clsx from 'clsx';

type StyleProps = {
  enableLinks?: boolean;
};
const useStyles = (props: StyleProps) => (theme: Theme) => ({
  root: {
    '&': {
      ...MarkdownTheme.root,
      fontWeightBold: 700,
      fontWeightLight: 300,
      fontWeightMedium: 500,
      fontWeightRegular: 400,
    },
    '& *': {
      overflowWrap: 'break-word',
    },
    '& > h1': {
      ...MarkdownTheme.h1,
    },
    '& > h2': {
      ...MarkdownTheme.h2,
    },
    '& > h3': {
      ...MarkdownTheme.h3,
    },
    '& > h4': {
      ...MarkdownTheme.h4,
    },
    '& > h5': {
      ...MarkdownTheme.h5,
    },
    '& > h6': {
      ...MarkdownTheme.h6,
    },
    '& > p': {
      ...MarkdownTheme.body1,
      whiteSpace: 'break-spaces',
    },
    '& del': {
      color: 'grey',
    },
    '& a': {
      ...(!props?.enableLinks ? { ...MarkdownTheme.linkDisabled } : {}),
    },
  },
});

export async function markdownToHtml(markdown: string) {
  // This converter does not support the markdown quote and task formats
  const result = await remark().use(gfm).use(html).process(markdown);
  return result.toString();
}

interface HtmlMarkdownViwerProps {
  markdown: string;
  className?: string;
  enableLinks?: boolean;
}

const HtmlMarkdownViwer = ({
  markdown,
  className,
  enableLinks,
}: HtmlMarkdownViwerProps) => {
  const [content, setContent] = React.useState('');
  const theme = useTheme();
  const classes = useStyles({ enableLinks })(theme);
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    markdownToHtml(markdown).then(setContent).catch(console.warn);
    return () => {
      setContent('');
    };
  }, [markdown]);

  React.useEffect(() => {
    if (divRef?.current && content) {
      const elements = divRef.current?.querySelectorAll?.('a') ?? [];
      elements.forEach((el) => {
        el?.setAttribute?.('target', '_blank');
        el?.setAttribute?.('rel', 'noopener noreferrer');
      });
    }
  }, [divRef, content]);

  if (!content) {
    return null;
  }

  return (
    <div
      ref={divRef}
      className={clsx(className, classes.root)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default HtmlMarkdownViwer;
