import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import type {
  BillingInformation,
  AdminBillingInformation,
} from '@bamboo/core-lib/src/api/types';

type InitialState = {
  billingInformation: BillingInformation | undefined;
  billingInformationByUserID: {
    [userID: string]: AdminBillingInformation | undefined;
  };
};

const initialData: InitialState = {
  billingInformation: undefined,
  billingInformationByUserID: {},
};

let store = createStore<InitialState>(() => initialData);

export const setBillingInformation = (
  billingInformation: BillingInformation
) => {
  store.setState({ billingInformation });
};

export const setAdminBillingInformation = (
  adminBillingInformation: AdminBillingInformation
) => {
  let billingInformationByUserID = store.getState().billingInformationByUserID;
  billingInformationByUserID[adminBillingInformation.userID] =
    adminBillingInformation;
  store.setState({ billingInformationByUserID });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function state() {
  return store.getState();
}

export function reset(
  newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export const useBillingInformation = () => useStore(store, (s) => s);
