import {
  SettingsKeys,
  setLocalConfig,
  getLocalConfig,
} from '@bamboo/ui-lib/src/hooks/localConfig';
import { setCart, state } from '../../repo/cart';
import { state as userState } from '../../repo/session';
import { api } from '../clients';
import { CartFormCreate, Cart, PriceMode } from '../types';
import { safeNumber } from '@bamboo/ts-utils';
type Variation = Cart['cartProducts'][0]['variations'][0];

export function createNewCart(form: CartFormCreate): Promise<Cart> {
  const { user } = userState();

  return new Promise<Cart>((resolve, reject) => {
    api
      .cartCreate({ ...form, userID: user?.userID })
      .then((r) => {
        const cart = r.data.item;
        setCart(cart);
        setLocalConfig(SettingsKeys.cartID, cart.cartID);
        resolve(cart);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function createCartItem(
  cartID: string,
  form: CartFormCreate
): Promise<Cart> {
  return new Promise<Cart>((resolve, reject) => {
    api
      .cartCreateItem(form, { params: { cartID } })
      .then((r) => {
        const cart = r.data.item;
        setCart(cart);
        resolve(cart);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function addCartItem(
  form: Omit<CartFormCreate, 'userID' | 'session'>
): Promise<Cart> {
  return new Promise<Cart>((resolve, reject) => {
    const config = getLocalConfig(SettingsKeys.cartID);
    const cartID = config?.value;

    if (!cartID) {
      createNewCart(form).then(resolve).catch(reject);
      return;
    }
    createCartItem(cartID, form).then(resolve).catch(reject);
  });
}

export function removeCartItem(cartID: string, caprID: number): Promise<Cart> {
  return new Promise<Cart>((resolve, reject) => {
    api
      .cartDeleteItem({ caprID }, { params: { cartID } })
      .then((r) => {
        const cart = r.data.item;
        setCart(cart);
        resolve(cart);
      })
      .catch(reject);
  });
}

export function calculateDiscounts(
  total: number,
  vouchers: Cart['cartVouchers']
) {
  let totalWithDiscounts = total;
  const discountList = vouchers
    .map((voucher) => {
      const amount = safeNumber(voucher.priceRules.discountAmount);
      const percentage = safeNumber(voucher.priceRules.discountPercentage);
      return { amount, percentage };
    })
    .sort((a, b) => {
      if (!isNaN(a.amount) && isNaN(b.amount)) {
        return -1;
      }
      if (isNaN(a.amount) && !isNaN(b.amount)) {
        return 1;
      }
      return b.percentage - a.percentage;
    });

  discountList.forEach(({ amount, percentage }) => {
    if (!isNaN(amount)) {
      totalWithDiscounts -= amount;
      return;
    }
    if (!isNaN(percentage)) {
      let pv = percentage;
      if (pv > 100) {
        pv = 100;
      }
      const value = (totalWithDiscounts * pv) / 100;
      totalWithDiscounts -= value;
      return;
    }
  });

  if (totalWithDiscounts < 0) {
    totalWithDiscounts = 0;
  }

  return totalWithDiscounts;
}

export function calculateItemPrice(
  defaultPrice: number,
  variations: Variation[]
) {
  let total = defaultPrice;
  variations.forEach((va) => {
    switch (va?.priceMode) {
      case PriceMode.sum:
        total += va.price;
        break;
      case PriceMode.subtract:
        total -= va.price;
        break;
    }
  });
  return total;
}

export function calculateFilmmakerRevenue(
  defaultPrice: number,
  variations: Variation[]
) {
  let total = defaultPrice;
  variations.forEach((va) => {
    switch (va?.priceMode) {
      case PriceMode.sum:
        total += va.price;
        break;
      case PriceMode.subtract:
        total -= va.price;
        break;
    }
  });
  // Net amount of commission received by the filmmaker from his sales.
  // 60% of the sale value of the individual clip minus 12% tax.
  const percentage = total * 0.6;
  const taxes = percentage * 0.12;
  return percentage - taxes;
}
