import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import type { Cart, ManagerCartView } from '@bamboo/core-lib/src/api/types';

export enum CartQueryOptions {
  Voucher = 'voucher',
}

type InitialState = {
  cartByID: { [cartID: string]: Cart | undefined };
  managerCartByID: { [cartID: string]: ManagerCartView | undefined };
};

const initialData: InitialState = {
  cartByID: {},
  managerCartByID: {},
};

let store = createStore<InitialState>(() => initialData);

export const setCart = (cart: Cart) => {
  let cartByID = store.getState().cartByID;
  cartByID[cart.cartID] = cart;
  store.setState({ cartByID });
};

export const clearCart = (cartID: string) => {
  let cartByID = store.getState().cartByID;
  delete cartByID[cartID];
  store.setState({ cartByID });
};

export const setManagerCart = (cart: ManagerCartView) => {
  let managerCartByID = store.getState().managerCartByID;
  managerCartByID[cart.cartID] = cart;
  store.setState({ managerCartByID });
};

export const setManagerCarts = (carts: ManagerCartView[]) => {
  let managerCartByID = store.getState().managerCartByID;
  carts.forEach((cart) => {
    managerCartByID[cart.cartID] = cart;
  });
  store.setState({ managerCartByID });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function reset(
  newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export function state() {
  return store.getState();
}

export const useCart = () => useStore(store, (s) => s);
