import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { CreditCard } from '@bamboo/core-lib/src/api/types';
import { ArrayUtils } from '@bamboo/ts-utils';

type InitialState = {
  creditCards: CreditCard[];
};

const initialData: InitialState = {
  creditCards: [],
};

let store = createStore<InitialState>(() => initialData);

export const setCreditCards = (creditCards: CreditCard[]) => {
  store.setState({ creditCards });
};

export const setCreditCard = (creditCard: CreditCard) => {
  const { creditCards } = store.getState();
  store.setState({
    creditCards: ArrayUtils.removeDuplicates([...creditCards, creditCard]),
  });
};

export const removeCreditCard = (card: CreditCard) => {
  const { creditCards } = store.getState();
  let arr = [...creditCards];
  const index = arr.findIndex((c) => c.crcaID === card.crcaID);
  if (index > -1) {
    arr.splice(index, 1);
  }
  store.setState({ creditCards: arr });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function state() {
  return store.getState();
}

export function reset(
  newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export const useCreditCard = () => useStore(store, (s) => s);
