import {
  api,
  mediaApi,
  adminApi,
  adminSchemas,
  schemas,
} from './clients';

export { api, mediaApi, adminApi, adminSchemas, schemas };

export default api;
