import { reset as userReset } from './user';
import { reset as cartReset } from './cart';
import { reset as billiReset } from './billingInformation';
import { reset as credReset } from './creditCard';
import { reset as clipsReset } from './clips';
import { reset as productReset } from './product';

export function resetRepositories() {
  userReset();
  cartReset();
  billiReset();
  credReset();
  productReset();
  clipsReset((current) => ({
    clipsListed: current.clipsListed,
    clipVariation: current.clipVariation,
    categories: current.categories,
    clipBySlug: current.clipBySlug,
    playlists: current.playlists,
    clipCategory: current.clipCategory,
  }));
}
