import { api, adminApi } from './clients';

export type AdminFilmmakerInfo = Awaited<
  ReturnType<typeof adminApi.filmmakerGet>
>['data']['item'];

export type AdminFilmmakerInfoListParams = Parameters<
  typeof adminApi.filmmakerList
>[0];

export type AdminFilmmakerFormUpdate = {
  avatar: File | string;
  headerImage: File | string;
} & Omit<
  Parameters<typeof adminApi.filmmakerPatch>[0],
  'avatar' | 'headerImage'
>;

export type AdminMarketplaceUser = Awaited<
  ReturnType<typeof adminApi.marketplaceuserGet>
>['data']['item'];

export type AdminMarketplaceRecipient = Awaited<
  ReturnType<typeof adminApi.marketplacerecipientGet>
>['data']['item'];

export type AdminBillingInformation = Awaited<
  ReturnType<typeof adminApi.billingInformationGet>
>['data']['item'];

export type AdminListPlaylistParams = Parameters<
  typeof adminApi.playlistList
>[0];

export type FilmmakerProfile = Awaited<
  ReturnType<typeof api.filmmakerProfileGet>
>['data']['item'];

export type Clip = Awaited<
  ReturnType<typeof api.clipsListClips>
>['data']['items'][0];

export type ClipsListParams = Parameters<typeof api.clipsListClips>[0];
export type ClipsGetParamsV2 = Parameters<typeof api.clipsGetClipV2>[0];

export type Category = Awaited<
  ReturnType<typeof api.categoryList>
>['data']['items'][0];

export type ClipCategory = Awaited<
  ReturnType<typeof api.clipCategoryList>
>['data']['items'][0];

export type ClipCategoryListParams = Parameters<typeof api.clipCategoryList>[0];

export type Playlist = Awaited<
  ReturnType<typeof api.playlistList>
>['data']['items'][0];

export type AdminPlaylistUpdateFormParams = {
  image?: File | string | null;
  thumbnail?: File | string | null;
} & Omit<Parameters<typeof adminApi.playlistPut>[0], 'image' | 'thumbnail'>;

export type ClipVariations = Awaited<
  ReturnType<typeof api.clipsGetClipVariations>
>['data']['item'];

export type CartFormCreate = Parameters<typeof api.cartCreate>[0];

export type Cart = Awaited<ReturnType<typeof api.cartCreate>>['data']['item'];
export type Checkout = Awaited<
  ReturnType<typeof api.cartCreateCheckout>
>['data']['item'];

export type GeoLocation = Awaited<
  ReturnType<typeof api.geoLocationList>
>['data']['items'][0];

export type PaymentMethod = Awaited<
  ReturnType<typeof api.marketplaceListPaymentMethods>
>['data']['items'][0];

export type CreditCard = Awaited<
  ReturnType<typeof api.creditCardsCreate>
>['data']['item'];

export type CreditCardForm = Parameters<typeof api.creditCardsCreate>[0] & {
  number: string;
  cvv: string;
};

export type PaymentForm = Parameters<typeof api.marketplacePayment>[0];

export type Payment = Awaited<
  ReturnType<typeof api.marketplacePayment>
>['data']['item'];

export type OnboardingForm = Parameters<typeof api.authCreateAccount>[0];

export type AuthResponse = Awaited<
  ReturnType<typeof api.authCreateAccount>
>['data']['item'];

export type BillingInformationForm = Parameters<
  typeof api.billingInformationCreate
>[0];

export type BillingInformation = Awaited<
  ReturnType<typeof api.billingInformationCreate>
>['data']['item'];

export type UserUpdateForm = Parameters<typeof api.usersPatchUserByID>[0];

export type UserMe = Awaited<
  ReturnType<typeof api.usersGetProfileMe>
>['data']['item'];

export type FavoriteProduct = Awaited<
  ReturnType<typeof api.favoriteProductList>
>['data']['items'][0];

// DownloadableClip
export type DownloadableClip = Awaited<
  ReturnType<typeof api.downloadableListClips>
>['data']['items'][0];

export type MarketplaceRecipientFormData = Parameters<
  typeof api.marketplaceRecipientCreate
>[0];

export type MarketplaceRecipient = Awaited<
  ReturnType<typeof api.marketplaceRecipientGet>
>['data']['item'];

export type MarketplaceUserFormData = Parameters<
  typeof api.marketplaceUserCreate
>[0];

export type MarketplaceUser = Awaited<
  ReturnType<typeof api.marketplaceUserGet>
>['data']['item'];

export type FilmmakerSaleParams = Parameters<typeof api.filmmakerListSales>[0];

export type FilmmakerSale = Awaited<
  ReturnType<typeof api.filmmakerListSales>
>['data']['items'][0];

export type FilmmakerCommissionInfoParams = Parameters<
  typeof api.dashboardFilmmakerCommissionInfo
>[0];
export type FilmmakerCommissionInfo = Awaited<
  ReturnType<typeof api.dashboardFilmmakerCommissionInfo>
>['data']['items'][0];

export type ProductListParams = Parameters<typeof api.productList>[0];
export type FilmmakerProduct = Awaited<
  ReturnType<typeof api.productList>
>['data']['items'][0];

export type Authorization = {
  file: File | string;
  name: string;
  releaseType: string;
};

export type CreateProductForm = {
  authorizations: Authorization[];
} & Omit<Parameters<typeof api.productCreate>[0], 'authorizations'>;

export type UpdateProductForm = {
  authorizations: Authorization[];
} & Omit<Parameters<typeof api.productUpdate>[0], 'authorizations'>;

export type UpdateAdminProductForm = {
  authorizations: Authorization[];
} & Omit<Parameters<typeof adminApi.productUpdate>[0], 'authorizations'>;

export type UpdateProductPriceForm = Parameters<
  typeof adminApi.productUpdatePrice
>[0];

export type ApprovalStatus = Awaited<
  ReturnType<typeof api.productCreateApproval>
>['data']['item'];

export type DashboardFilmmakerLicenses = Awaited<
  ReturnType<typeof api.dashboardFilmmakerLicenses>
>['data']['item'];

export type DashboardFilmmakerLicensesParams = Parameters<
  typeof api.dashboardFilmmakerLicenses
>[0];

export type AdminProductListParams = Parameters<typeof adminApi.productList>[0];

export type AdminProductListing = Awaited<
  ReturnType<typeof adminApi.productList>
>['data']['items'][0];

export type ProductReview = Awaited<
  ReturnType<typeof adminApi.productGetReview>
>['data']['item'];

export type AdminSaleParams = NonNullable<
  Parameters<typeof adminApi.salesList>[0]
>;

export type AdminSale = Awaited<
  ReturnType<typeof adminApi.salesGet>
>['data']['item'];

export type ReviewForm = {
  authorizations: Authorization[];
} & Omit<Parameters<typeof adminApi.productUpdateReview>[0], 'authorizations'>;

export type SaleAction = Parameters<typeof adminApi.salesActons>[0];

export type ProductAddres = CreateProductForm['address'];

export type ProductTemplateForm = Parameters<
  typeof adminApi.productTemplateUpdate
>[0];

export type ProductConfigurationOptionForm = Parameters<
  typeof adminApi.productConfigurationOptionUpdate
>[0];

export type ProductTemplate = Awaited<
  ReturnType<typeof adminApi.productTemplateGet>
>['data']['item'];

export type ProductConfiguration = Awaited<
  ReturnType<typeof adminApi.productConfigurationGet>
>['data']['item'];

export type ProductConfigurationOption = Awaited<
  ReturnType<typeof adminApi.productConfigurationOptionGet>
>['data']['item'];

export type UserInformation = Awaited<
  ReturnType<typeof adminApi.userGet>
>['data']['item'];

export type UserDownloadable = Awaited<
  ReturnType<typeof adminApi.downloadablesList>
>['data']['items'][0];

export type UserDownloadableParams = Parameters<
  typeof adminApi.downloadablesList
>[0];

export type PatchUserInformationForm = Parameters<
  typeof adminApi.userUpdate
>[0];

export type UserListParams = Parameters<typeof adminApi.userList>[0];

export type FavoriteDownloadableClip = Awaited<
  ReturnType<typeof adminApi.favoriteProductListClips>
>['data']['items'][0];

export type ProductPlaylist = Awaited<
  ReturnType<typeof adminApi.productPlaylistGet>
>['data']['items'][0];

export type CreateAdminProductForm = {
  authorizations: Authorization[];
} & Omit<Parameters<typeof adminApi.productCreate>[0], 'authorizations'>;

export type UploadedMedia = Awaited<
  ReturnType<typeof adminApi.uploadedMediaCreate>
>['data']['item'];

export type UploadedMediaListParams = Parameters<
  typeof adminApi.uploadedMediaList
>[0];

export type Collection = Awaited<
  ReturnType<typeof adminApi.collectionGet>
>['data']['item'];

export type ManagerCartForm = Parameters<typeof adminApi.cartCreate>[0];

export type ManagerCartView = Awaited<
  ReturnType<typeof adminApi.cartGet>
>['data']['item'];

export type EmailValidateStatus = Awaited<
  ReturnType<typeof api.authValidateEmail>
>['data']['item'];

export type ManagerCartListParams = Parameters<typeof adminApi.cartList>[0];

export type CreateCollectionForm = {
  image?: File | string | null;
  thumbnail?: File | string | null;
} & Omit<
  Parameters<typeof adminApi.collectionCreate>[0],
  'image' | 'thumbnail'
>;

export type ListCollectionParams = Parameters<
  typeof adminApi.collectionList
>[0];

export type Voucher = Awaited<
  ReturnType<typeof adminApi.voucherGet>
>['data']['item'];
export type VoucherListParams = Parameters<typeof adminApi.voucherList>[0];
export type VoucherCreateForm = Parameters<typeof adminApi.voucherCreate>[0];
export type VoucherUpdateForm = Parameters<typeof adminApi.voucherUpdate>[0];
export type ProductUpdatePriceForm = Parameters<
  typeof adminApi.productUpdatePrice
>[0];

export type AdminProductsUploadedListParams = Parameters<
  typeof adminApi.uploadedProductsList
>[0];

export type Term = Awaited<ReturnType<typeof api.termGet>>['data']['item'];
export type PendingUserTerms = Awaited<
  ReturnType<typeof api.termGetPending>
>['data']['item'];

export type NewslettersRegistration = Awaited<
  ReturnType<typeof adminApi.newslettersRegistrationList>
>['data']['items'][0];

export type NewslettersRegistrationParams = Parameters<
  typeof adminApi.newslettersRegistrationList
>[0];

export type NewslettersRegistrationForm = Parameters<
  typeof api.newslettersRegistrationCreate
>[0];

export type JobApplicationCreateForm = Parameters<
  typeof api.jobApplicationCreate
>[0];

export type JobApplication = Awaited<
  ReturnType<typeof adminApi.jobApplicationList>
>['data']['items'][0];

export type JobApplicationParams = Parameters<
  typeof adminApi.jobApplicationList
>[0];

export type FilmmakerApplication = Awaited<
  ReturnType<typeof adminApi.filmmakerApplicationList>
>['data']['items'][0];

export type FilmmakerApplicationCreateForm = Parameters<
  typeof api.filmmakerApplicationCreate
>[0];

export type FilmmakerApplicationParams = Parameters<
  typeof adminApi.filmmakerApplicationList
>[0];

export type PaymentDetails = {
  phone: string;
  dob: string;
  email: string;
  documentType: string;
  documentNumber: string;
  name: string;
  surname: string;
  companyName?: string;
  projectName?: string;
  orderOfService?: string;
  streetComplement?: string;
} & BillingInformationForm['billingAddress'];

export enum VariationField {
  videoFormatSelector = 'videoFormatSelector',
  select = 'select',
}

export enum PriceMode {
  sum = 'sum',
  subtract = 'subtract',
}

export const PriceModeLable = {
  [PriceMode.sum]: 'Adição',
  [PriceMode.subtract]: 'Subtração',
};

export enum SupportedPaymentMethod {
  boleto = 'pagarme.boleto',
  creditcard = 'pagarme.card',
  pix = 'pagarme.pix',

  voucher = 'voucher',
}

export enum PaymentStatus {
  paid = 'paid',
  rejected = 'rejected',
  refunded = 'refunded',
  refused = 'refused',
  pending = 'pending',
  waiting = 'waiting_payment',
  authorized = 'authorized',
  canceled = 'canceled',
  processing = 'processing',
  failed = 'failed',
}
