import { create } from 'zustand';

type Callback = () => void;

type LoginModalStore = {
  isOpen: boolean;
  startWith: 'login' | 'register';
  callback?: Callback;
  setCallback: (callback?: Callback) => void;
  setIsOpen: (isOpen: boolean) => void;
  setStartWith: (startWith: 'login' | 'register') => void;
};

const loginModalStore = create<LoginModalStore>((set) => ({
  isOpen: false,
  startWith: 'login',
  setIsOpen: (newValue) => set((state) => ({ ...state, isOpen: newValue })),
  setStartWith: (newValue) =>
    set((state) => ({ ...state, startWith: newValue })),
  callback: undefined,
  setCallback: (callback) => set((state) => ({ ...state, callback })),
}));

export const useLoginModalStore = loginModalStore;
