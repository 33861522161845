import type { EmotionCache } from '@emotion/cache';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import nextI18nextConfig from '../../next-i18next.config';
import { AppProviders } from '../AppProviders';
import { PendingTermDialog } from '../PendingTermDialog';
import { useOneTimeLogin } from '@/lib/hooks/oneTimeLogin';
import { useGetUserMe } from '@bamboo/core-lib/hooks/user';
import { GoogleTagManager } from '@next/third-parties/google';

/**
 * Import global styles, global css or polyfills here
 * i.e.: import '@/assets/theme/style.scss'
 */
import '../styles/global.css';
import Analytics from './_analytics';
import FacebookPixel from './_facebookPixel';
import { LoginModal } from '@/features/auth/components/loginModal';
import { useLoginModalStore } from '@/features/auth/components/loginModal/store';

/**
 * Local fonts
 * @link https://fontsource.org/docs/guides/nextjs
 */
// import '@fontsource/inter/400.css';
// import '@fontsource/inter/700.css';
// @link https://fontsource.org/docs/variable-fonts
// import '@fontsource/inter/variable.css';

// Workaround for https://github.com/zeit/next.js/issues/8592
export type MyAppProps = AppProps & {
  /** Will be defined only is there was an error */
  err?: Error;
  emotionCache?: EmotionCache;
};

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
process.env.API_PATH = process.env?.NEXT_PUBLIC_API_PATH;
process.env.MEDIA_API_PATH = process.env?.NEXT_PUBLIC_MEDIA_API_PATH;
process.env.PAGARME_CRYPTO_KEY = process.env?.NEXT_PUBLIC_PAGARME_CRYPTO_KEY;
process.env.OAUTH_LOGIN_URL = process.env?.NEXT_PUBLIC_OAUTH_LOGIN_URL;
process.env.GOOGLE_MAPS_API_KEY = process.env?.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
process.env.ECOMMERCE_URL = process.env?.NEXT_PUBLIC_ECOMMERCE_URL;
process.env.INSTAGRAM_URL = process.env?.NEXT_PUBLIC_INSTAGRAM_URL;
process.env.GOOGLE_SITE_VERIFICATION =
  process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION;
process.env.CONTACT_LINK = process.env.NEXT_PUBLIC_CONTACT_LINK;

const MyApp = (appProps: MyAppProps) => {
  const { isOpen } = useLoginModalStore();
  const { Component, pageProps, err, emotionCache } = appProps;
  useOneTimeLogin();
  useGetUserMe();

  return (
    <AppProviders emotionCache={emotionCache}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script id="dataLayer-script">
          {`window.dataLayer = window.dataLayer || []`}
        </script>
        <script type="text/javascript">
          {`var _iub = _iub || [];
_iub.csConfiguration = {"siteId":3858979,"cookiePolicyId":17012287,"lang":"pt-BR","storage":{"useSiteId":true}};`}
        </script>
        <script
          type="text/javascript"
          src="https://cs.iubenda.com/autoblocking/3858979.js"
        />
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          async
        />
      </Head>
      {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
      <GoogleTagManager
        gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER ?? ''}
      />

      <Component {...pageProps} err={err} />
      {isOpen && <LoginModal />}
      <PendingTermDialog />
      <FacebookPixel />
      <Analytics />
    </AppProviders>
  );
};

/**
 * Generally don't enable getInitialProp if you don't need to,
 * all your pages will be served server-side (no static optimizations).
 */
/*
MyApp.getInitialProps = async appContext => {
   // calls page's `getInitialProps` and fills `appProps.pageProps`
   const appProps = await App.getInitialProps(appContext)
   return { ...appProps }
}
*/

export default appWithTranslation(MyApp, {
  ...nextI18nextConfig,
});
