import { api, adminApi, mediaApi } from '../clients';
import { state, setUser, setMediaToken } from '../../repo/session';
import { setUserProfile, setUsers } from '../../repo/user';
import {
  UserMe,
  UserUpdateForm,
  EmailValidateStatus,
  UserListParams,
  UserInformation,
} from '../types';
import axios, { CancelTokenSource } from 'axios';
import { getTokenMedia } from './media';
export function getUserMe(): Promise<UserMe> {
  return new Promise<UserMe>((resolve, reject) => {
    const { jwt, user } = state();
    if (!jwt) {
      reject('empty authentication token');
      return;
    }
    if (!user?.userID) {
      reject('empty user id');
      return;
    }
    const Authorization = `Bearer ${jwt}`;

    api
      .usersGetProfileMe({
        params: { userID: user?.userID },
        headers: { Authorization },
      })
      .then((r) => {
        const u = r.data.item;
        setUserProfile(u);
        resolve(u);
      })
      .catch(reject);
  });
}

export type UpdatedUser = Awaited<
  ReturnType<typeof api.usersPatchUserByID>
>['data']['item'];

function setUpdatedUser(user: UpdatedUser) {
  setUser({
    userID: user.userID,
    applID: user.applID,
    avatar: user?.avatar ?? '',
    name: user.name,
  }),
    setUserProfile((p) => ({ ...p, ...user } as UserMe));
}

export function updateUser(form: UserUpdateForm): Promise<UpdatedUser> {
  return new Promise<UpdatedUser>((resolve, reject) => {
    const { jwt, user } = state();
    if (!jwt) {
      reject('empty authentication token');
      return;
    }
    if (!user?.userID) {
      reject('empty user id');
      return;
    }
    const Authorization = `Bearer ${jwt}`;
    api
      .usersPatchUserByID(form, {
        params: { userID: user?.userID },
        headers: { Authorization },
      })
      .then((r) => {
        const u = r.data.item;
        setUpdatedUser(u);
        resolve(u);
      })
      .catch(reject);
  });
}

export function updateUserAvatar(file: File): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    const { media, jwt } = state();
    let tokenMedia = media;
    if (!tokenMedia) {
      await api
        .uploadedMediaAuth(
          {},
          {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        )
        .then((r) => {
          tokenMedia = r.data.item.jwt;
          setMediaToken(tokenMedia);
        })
        .catch(reject);
    }
    const data = new FormData();
    data.append('file', file);
    mediaApi.axios
      .post('/api/public-media/upload', data, {
        headers: { Authorization: `Bearer ${tokenMedia}` },
      })
      .then((r) => {
        const { url } = r.data.data.item;
        updateUser({ avatar: url })
          .then(() => resolve())
          .catch(reject);
      })
      .catch(reject);
  });
}

export function updateFilmmakerHeaderImage(file: File): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    let token = '';
    try {
      token = await getTokenMedia();
    } catch (e) {
      reject(e);
      return;
    }

    const data = new FormData();
    data.append('file', file);
    mediaApi.axios
      .post('/api/public-media/upload', data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((r) => {
        const { url } = r.data.data.item;
        updateUser({ filmmaker: { headerImage: url } })
          .then(() => resolve())
          .catch(reject);
      })
      .catch(reject);
  });
}

export const makeCanceableValidateEmail = () => {
  let cancel: CancelTokenSource | undefined;
  return async (data: { email: string; applID: string }) => {
    return new Promise<EmailValidateStatus>((resolve, reject) => {
      if (cancel) {
        cancel.cancel();
      }
      cancel = axios.CancelToken.source();
      api
        .authValidateEmail(data, {
          cancelToken: cancel.token,
        })
        .then((r) => {
          const resp = r.data.item;
          resolve(resp);
        })
        .catch(reject);
    });
  };
};

export const validateEmail = makeCanceableValidateEmail();

export const makeCanceableListUsers = () => {
  let cancel: CancelTokenSource | undefined;
  return async (f?: UserListParams) => {
    return new Promise<UserInformation[]>((resolve, reject) => {
      if (cancel) {
        cancel.cancel();
      }
      cancel = axios.CancelToken.source();
      adminApi
        .userList({
          ...f,
          cancelToken: cancel.token,
        })
        .then((r) => {
          const resp = r.data.items;
          resolve(resp);
          setUsers(resp);
        })
        .catch(reject);
    });
  };
};

export const listUsers = makeCanceableListUsers();
