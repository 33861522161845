import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    clipRule="evenodd"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M20.75 8.414c0-.464-.184-.909-.513-1.237l-5.414-5.414a1.746 1.746 0 0 0-1.237-.513H6c-.729 0-1.429.29-1.945.805A2.755 2.755 0 0 0 3.25 4v16c0 .729.29 1.429.805 1.945A2.755 2.755 0 0 0 6 22.75h12A2.75 2.75 0 0 0 20.75 20zm-1.5 0V20c0 .69-.56 1.25-1.25 1.25H6A1.252 1.252 0 0 1 4.75 20V4A1.252 1.252 0 0 1 6 2.75h7.586a.25.25 0 0 1 .177.073l5.414 5.414a.25.25 0 0 1 .073.177z" />
    <path d="M13.25 2.5V6A2.75 2.75 0 0 0 16 8.75h3.5a.75.75 0 0 0 0-1.5H16c-.69 0-1.25-.56-1.25-1.25V2.5a.75.75 0 0 0-1.5 0zM8.272 12.182l1.25 5a.75.75 0 0 0 1.424.097L12 14.644l1.054 2.635a.75.75 0 0 0 1.424-.097l1.25-5a.752.752 0 0 0-.546-.91.752.752 0 0 0-.91.546l-.687 2.75-.889-2.222a.75.75 0 0 0-1.392 0l-.889 2.222-.687-2.75a.752.752 0 0 0-.91-.546.752.752 0 0 0-.546.91z" />
  </svg>
);
export default SvgComponent;
