import type { BillingInformation } from '../api/types';

export async function encryptSHA256(data: string) {
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
}
export async function encryptBillingInformation(
  billingInformation: BillingInformation
) {
  const [email, phone, city, state] = await Promise.all([
    encryptSHA256(billingInformation.email),
    encryptSHA256(
      `(${billingInformation.billingPhone.ddd})${billingInformation.billingPhone.number}`
    ),
    encryptSHA256(billingInformation.billingAddress.city),
    encryptSHA256(billingInformation.billingAddress.state),
  ]);
  return {
    email,
    phone,
    state,
    city,
  };
}
