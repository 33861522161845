import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 359.448 359.448"
    {...props}
  >
    <path d="M354.724 171.16c0-26.69-14.734-49.991-36.486-62.236C304.906 46.92 245 0 173.319 0 97.486 0 34.83 52.514 26.559 119.847 13.112 132.835 4.724 151.031 4.724 171.16c0 39.358 32.021 71.379 71.379 71.379a5 5 0 0 0 5-5v-132.76a5 5 0 0 0-5-5c-3.361 0-6.664.25-9.904.703 15.92-38.884 57.928-66.729 107.12-66.729 49.269 0 91.329 27.933 107.192 66.913a4.99 4.99 0 0 0-2.166 4.114v132.76a5 5 0 0 0 5 5c1.654 0 3.289-.077 4.915-.188-11.071 34.443-41.038 61.811-79.183 72.519-4.566-7.385-12.735-12.309-22.056-12.309h-27.404c-14.312 0-25.917 11.603-25.917 25.916v5.054c0 14.314 11.605 25.918 25.917 25.918h27.404c13.065 0 23.87-9.672 25.653-22.247 51.311-13.492 90.777-52.673 100.435-101.19 24.535-11.308 41.615-36.116 41.615-64.853z" />
  </svg>
);
export default SvgComponent;
