import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { authConfig } from '@/features/auth/auth.config';
import type { OnboardingFormProps } from '@bamboo/core-lib/components/Auth/OnboardingForm';
import AuthOnboardingForm from '@bamboo/core-lib/components/Auth/OnboardingForm';

export const OnboardingForm = (
  props: Omit<OnboardingFormProps, 'Link' | 'labels'>
) => {
  const { t } = useTranslation(authConfig.i18nNamespaces);

  const labels: OnboardingFormProps['labels'] = {
    email: 'nome@email.com',
    password: 'Digite sua senha...',
    password_confirm: 'Digite sua senha novamente...',
    user_first_name: 'Digite seu nome...',
    user_last_name: 'Digite seu sobrenome...',
    reading_terms: 'Eu li e aceito o(s)',
    terms: 'termos de uso e privacidade',
    onboarding_submit: 'Criar conta',
    password_error:
      'A senha deve conter no mínimo 8 caracteres e ao menos uma letra e um número.',
    email_error: 'E-mail inválido.',
  };

  return <AuthOnboardingForm labels={labels} Link={Link} {...props} />;
};
