import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import type {
  Clip,
  ClipVariations,
  Category,
  Playlist,
  FavoriteProduct,
  DownloadableClip,
  FavoriteDownloadableClip,
  ClipCategory,
} from '@bamboo/core-lib/src/api/types';

export enum ClipsSearchQueryOptions {
  CategorySlug = 'category_slug',
  SearchText = 'search_text',
  Tag = 'tag',
  Sort = 'sort',
}

export enum SortOptions {
  // relevance = 'relevance',
  bambooSelect = 'bambooSelect',
  alphabetical = 'alphabetical',
  createdAt = 'createdAt',
  favoriteAt = 'favoriteAt',
}

export enum OrderOptions {
  asc = 'asc',
  desc = 'desc',
}

export enum OldSortOptions {
  dateAsc = 'date_asc',
  dateDesc = 'date_desc',
  titleAsc = 'title_asc',
  titleDesc = 'title_desc',
}

export function getOldSearchQueryOptions(option: string) {
  return (
    (
      {
        [ClipsSearchQueryOptions.CategorySlug]: '_product_categories',
        [ClipsSearchQueryOptions.SearchText]: '_products_search',
        [ClipsSearchQueryOptions.Sort]: '_sort',
      } as any
    )?.[option] ?? option
  );
}

export function getSortOption(option: string) {
  return (
    (
      {
        [OldSortOptions.dateAsc]: `${SortOptions.createdAt}_${OrderOptions.asc}`,
        [OldSortOptions.dateDesc]: `${SortOptions.createdAt}_${OrderOptions.desc}`,
        [OldSortOptions.titleAsc]: `${SortOptions.alphabetical}_${OrderOptions.asc}`,
        [OldSortOptions.titleDesc]: `${SortOptions.alphabetical}_${OrderOptions.desc}`,
      } as any
    )?.[option] ?? option
  );
}

type InitialState = {
  clipBySlug: { [slug: string]: Clip | undefined };
  clipByPrinID: { [prinID: string]: string };
  clipVariation: { [slug: string]: ClipVariations | undefined };
  favoriteProductByID: { [prodID: string]: FavoriteProduct | undefined };
  clipsListed: string[];
  suggestedClips: string[];
  categories: Category[];
  clipCategory: ClipCategory[];
  clipCategoryV2: ClipCategoryV2[];
  playlists: Playlist[];
  downloadables: DownloadableClip[];
  clipDownByProdID: { [prodID: string]: FavoriteDownloadableClip | undefined };
  clipDownListed: string[];
};

const initialData: InitialState = {
  clipBySlug: {},
  clipByPrinID: {},
  clipVariation: {},
  favoriteProductByID: {},
  clipsListed: [],
  suggestedClips: [],
  categories: [],
  clipCategory: [],
  clipCategoryV2: [],
  playlists: [],
  downloadables: [],
  clipDownByProdID: {},
  clipDownListed: [],
};

let store = createStore<InitialState>(() => initialData);

export const setClips = (clips: Clip[] | ((p: Clip[]) => Clip[])) => {
  let list: Clip[] = [];
  let clipBySlug = store.getState().clipBySlug;
  let clipByPrinID = store.getState().clipByPrinID;
  if (Array.isArray(clips)) {
    list = clips;
  } else {
    const current = Object.keys(clipBySlug)
      .map((slug) => clipBySlug[slug])
      .filter((c) => !!c) as Clip[];
    list = clips(current);
  }
  const clipsListed = list.map((c) => {
    clipBySlug[c.slug] = c;
    clipByPrinID[c.prinID] = c.slug;
    return c.slug;
  });

  store.setState({
    clipBySlug,
    clipByPrinID,
    clipsListed,
  });
};

export const setClip = (clip: Clip) => {
  let clipBySlug = store.getState().clipBySlug;
  let clipByPrinID = store.getState().clipByPrinID;

  clipBySlug[clip.slug] = clip;
  clipByPrinID[clip.prinID] = clip.slug;
  store.setState({ clipBySlug, clipByPrinID });
};

export const setSuggestedClips = (clips: Clip[] | ((p: Clip[]) => Clip[])) => {
  let list: Clip[] = [];
  let clipBySlug = store.getState().clipBySlug;
  let clipByPrinID = store.getState().clipByPrinID;
  if (Array.isArray(clips)) {
    list = clips;
  } else {
    const current = Object.keys(clipBySlug)
      .map((slug) => clipBySlug[slug])
      .filter((c) => !!c) as Clip[];
    list = clips(current);
  }
  const suggestedClips = list.map((c) => {
    clipBySlug[c.slug] = c;
    clipByPrinID[c.prinID] = c.slug;
    return c.slug;
  });

  store.setState({
    clipBySlug,
    clipByPrinID,
    suggestedClips,
  });
};

export const setFavoriteDownloadables = (
  clips:
    | FavoriteDownloadableClip[]
    | ((p: FavoriteDownloadableClip[]) => FavoriteDownloadableClip[])
) => {
  let list: FavoriteDownloadableClip[] = [];
  let clipDownByProdID = store.getState().clipDownByProdID;
  if (Array.isArray(clips)) {
    list = clips;
  } else {
    const current = Object.keys(clipDownByProdID)
      .map((slug) => clipDownByProdID[slug])
      .filter((c) => !!c) as FavoriteDownloadableClip[];
    list = clips(current);
  }
  const clipDownListed = list.map((c) => {
    clipDownByProdID[c.prodID] = c;
    return c.prodID;
  });

  store.setState({
    clipDownByProdID,
    clipDownListed,
  });
};

export const setFavoriteDownloadable = (clip: FavoriteDownloadableClip) => {
  let clipDownByProdID = store.getState().clipDownByProdID;
  clipDownByProdID[clip.prodID] = clip;
  store.setState({ clipDownByProdID });
};

export const setClipVariation = (cv: ClipVariations) => {
  let clipVariation = store.getState().clipVariation;
  clipVariation[cv.slug] = cv;
  store.setState({ clipVariation });
};

export const setCategories = (
  categories: Category[] | ((p: Category[]) => Category[])
) => {
  if (Array.isArray(categories)) {
    store.setState({ categories });
  } else {
    const current = store.getState().categories;
    store.setState({
      categories: categories(current),
    });
  }
};

export const setClipCategories = (
  categories: ClipCategory[] | ((p: ClipCategory[]) => ClipCategory[])
) => {
  if (Array.isArray(categories)) {
    store.setState({ clipCategory: categories });
  } else {
    const current = store.getState().clipCategory;
    store.setState({
      clipCategory: categories(current),
    });
  }
};

export type ClipCategoryV2 = {
  parentName: string;
  parentOrder: number;
  childName: string;
  childSlug: string;
};
export const setClipCategoriesV2 = (
  categories: ClipCategoryV2[] | ((p: ClipCategoryV2[]) => ClipCategoryV2[])
) => {
  if (Array.isArray(categories)) {
    store.setState({ clipCategoryV2: categories });
  } else {
    const current = store.getState().clipCategoryV2;
    store.setState({
      clipCategoryV2: categories(current),
    });
  }
};

export const setPlaylists = (
  playlists: Playlist[] | ((p: Playlist[]) => Playlist[])
) => {
  if (Array.isArray(playlists)) {
    store.setState({ playlists });
  } else {
    const current = store.getState().playlists;
    store.setState({
      playlists: playlists(current),
    });
  }
};

export function makeSort(sort?: string) {
  if (!sort) {
    return {};
  }
  const split = getSortOption(sort).split('_');
  if (split?.[1] === OrderOptions.desc) {
    return {
      'order[desc]': split[0],
    };
  }
  return {
    'order[asc]': split[0],
  };
}

export const setPlaylist = (playlist: Playlist) => {
  const current = store.getState().playlists;
  store.setState({
    playlists: [...current, playlist],
  });
};

export const setFavoriteProducts = (
  fp: FavoriteProduct[] | ((p: FavoriteProduct[]) => FavoriteProduct[])
) => {
  let list: FavoriteProduct[] = [];
  let fpByID = store.getState().favoriteProductByID;
  if (Array.isArray(fp)) {
    list = fp;
  } else {
    const current = Object.keys(fpByID)
      .map((prodID) => fpByID[prodID])
      .filter((f) => !!f) as FavoriteProduct[];
    list = fp(current);
  }
  list.forEach((f) => {
    fpByID[f.prodID] = f;
  });

  store.setState({
    favoriteProductByID: fpByID,
  });
};

export const setFavoriteProduct = (fp: FavoriteProduct) => {
  let fpByID = store.getState().favoriteProductByID;
  fpByID[fp.prodID] = fp;

  store.setState({
    favoriteProductByID: fpByID,
  });
};

export const setDownloadableClips = (
  dc: DownloadableClip[] | ((p: DownloadableClip[]) => DownloadableClip[])
) => {
  let downloadables: DownloadableClip[] = [];
  if (Array.isArray(dc)) {
    downloadables = dc;
  } else {
    let current = store.getState().downloadables;
    downloadables = dc(current);
  }
  store.setState({ downloadables });
};

export const removeFavoriteProduct = (prodID: string) => {
  let fpByID = store.getState().favoriteProductByID;
  delete fpByID[prodID];
  store.setState({
    favoriteProductByID: fpByID,
  });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function state() {
  return store.getState();
}

export function reset(
  newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export const useClips = () => useStore(store, (s) => s);
