import React from 'react';
import { api } from '@bamboo/core-lib/src/api';
import { PendingUserTerms } from '@bamboo/core-lib/src/api/types';
import { useSessionStore } from '@bamboo/core-lib/src/repo/session';
import { useTerm, setTerm } from '@bamboo/core-lib/src/repo/term';

export const useGetPendingTerms = () => {
  const { jwt } = useSessionStore();
  const [pending, setPending] = React.useState<PendingUserTerms>();

  React.useEffect(() => {
    if (jwt) {
      api
        .termGetPending()
        .then((r) => {
          setPending(r.data.item);
        })
        .catch(console.warn);
    }
  }, [jwt]);

  return [pending] as [typeof pending];
};

export const useGetTerm = (slug: string) => {
  const [loading, setLoading] = React.useState(false);
  const { termByID, termBySlug } = useTerm();
  const termID = termBySlug[slug] ?? NaN;
  const term = !isNaN(termID) ? termByID[termID] : undefined;

  React.useEffect(() => {
    if (!term && slug) {
      setLoading(true);
      api
        .termGet({ params: { slug } })
        .then((r) => {
          setTerm(r.data.item);
        })
        .catch(console.warn)
        .finally(() => setLoading(false));
    }
  }, [slug, term]);

  return [loading, term] as [typeof loading, typeof term];
};
