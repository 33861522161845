import api from '@bamboo/core-lib/api';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { SessionQueryOptions } from '@bamboo/core-lib/repo/session';
import { asString, toQueryString } from '@bamboo/ts-utils';

export const useOneTimeLogin = () => {
  const router = useRouter();

  useEffect(() => {
    const { query, pathname } = router;
    const otp = asString(query?.[SessionQueryOptions.Otp]);
    if (otp) {
      api
        .authOneTimeLogin({ token: otp })
        .then(() => {
          router.replace(
            `${pathname}${toQueryString({
              ...query,
              [SessionQueryOptions.Otp]: undefined,
            })}`
          );
        })
        .catch(console.warn);
    }
  }, [router]);
};
