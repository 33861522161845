import { useTranslation } from 'next-i18next';
import { authConfig } from '@/features/auth/auth.config';
import AuthLoginForm from '@bamboo/core-lib/components/Auth/LoginForm';
import type { LoginFormProps } from '@bamboo/core-lib/components/Auth/LoginForm';

export const LoginForm = ({
  onSubmit,
  children,
  initialValues,
}: Omit<LoginFormProps, 'Link' | 'labels'>) => {
  const { t } = useTranslation(authConfig.i18nNamespaces);

  const labels = {
    email_username: 'Digite seu e-mail ou nome de usuário',
    remember_me: 'Lembrar minha senha',
    forgot_password: 'Esqueci minha senha',
    signin_submit: 'Entrar',
    password: 'Digite sua senha',
  };

  return (
    <AuthLoginForm
      labels={labels}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {children}
    </AuthLoginForm>
  );
};
