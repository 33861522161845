import { forwardRef, useState } from 'react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FilledInput,
  FilledInputProps,
  InputLabel,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import type { OutlinedInputProps, InputProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Background } from 'victory';

const StyledInput = styled(
  forwardRef((props: FilledInputProps, ref: any) => {
    return (
      <FilledInput
        ref={ref}
        size="small"
        disableUnderline
        {...props}
        inputProps={{
          ...props?.inputProps,
          style: {
            WebkitBoxShadow: '0 0 0 100px #fbfbfb inset',
          },
        }}
        style={{
          backgroundColor: '#fbfbfb',
          borderBottom: '1px solid #e9e9e9',
          ...props.style,
        }}
      />
    );
  })
)(({ theme }) => ({
  '&fieldset': {
    borderRadius: '200px',
  },

  '& input:-webkit-autofill': {
    borderRadius: '0px !important',
  },

  '&.MuiInput-underline:after': {
    borderBottomColor: '#FFFFFF',
  },
}));

export type PasswordInputProps = {
  error?: string;
  errorOnForm?: string;
  id?: string;
  formControlProps?: Omit<FormControlProps, 'error'>;
} & Omit<InputProps, 'error'>;

export const PasswordInput = forwardRef(
  (
    { id, error, errorOnForm, formControlProps, ...other }: PasswordInputProps,
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    console.log('errorOnForm on Pass: ', errorOnForm);

    const handleMouseDownPassword: React.MouseEventHandler<
      HTMLButtonElement
    > = (event) => {
      event.preventDefault();
    };

    return (
      <FormControl
        fullWidth
        error={Boolean(error)}
        margin="normal"
        {...formControlProps}
        style={{ marginTop: 0 }}
      >
        <StyledInput
          style={{
            textAlign: 'left',
          }}
          ref={ref}
          id={id}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="small"
              >
                {showPassword ? (
                  <VisibilityOutlinedIcon fontSize="small" />
                ) : (
                  <VisibilityOffOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          }
          {...other}
        />
        {error}
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);

export type PasswordOutlinedInputProps = {
  error?: string;
  id?: string;
  formControlProps?: Omit<FormControlProps, 'error'>;
} & Omit<OutlinedInputProps, 'error'>;

export const PasswordOutlinedInput = forwardRef(
  (
    { id, error, formControlProps, ...other }: PasswordOutlinedInputProps,
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword: React.MouseEventHandler<
      HTMLButtonElement
    > = (event) => {
      event.preventDefault();
    };

    return (
      <FormControl
        fullWidth
        error={Boolean(error)}
        margin="normal"
        {...formControlProps}
      >
        <InputLabel htmlFor={id}>{other?.label}</InputLabel>
        <OutlinedInput
          style={{
            textAlign: 'left',
          }}
          ref={ref}
          id={id}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="small"
              >
                {showPassword ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          }
          {...other}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
