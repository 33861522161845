import type { Clip, Cart, Checkout } from '@bamboo/core-lib/src/api/types';
import { sendGTMEvent } from '@next/third-parties/google';
import {
  calculateItemPrice,
  calculateDiscounts,
} from '@bamboo/core-lib/src/api/flows/cart';
import { encryptSHA256 } from '@bamboo/core-lib/src/utils/SHA256Enconder';
import { getCookie } from '@bamboo/core-lib/cookie';

const BrazilianCurrency = 'BRL';
export enum GTMEvents {
  ADDToWishlist = 'add_to_wishlist',
  ViewItemList = 'view_item_list',
  ViewItem = 'view_item',
  SelectItem = 'select_item',
  ADDToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  ViewCart = 'view_cart',
  Purchase = 'purchase',
  BeginCheckout = 'begin_checkout',

  CreateAccount = 'create_account',
  InitiatedCheckout = 'initiated_checkout',
  AddPaymentMethod = 'add_payment_method',
  PurchaseCompleted = 'purchase_completed',
  HomeView = 'home_view',
  PlaylistsPageView = 'playlists_page_view',
  SearchPageView = 'search_page_view',
  PlansPageView = 'plans_page_view',
  HowItWorksPageView = 'how_it_works_page_view',
  StudiosPageView = 'studios_page_view',
  FaqPageView = 'faq_page_view',
  PlaylistViewed = 'playlist_viewed',
  BasicButtonClicked = 'basic_button_clicked',
  StandardButtonClicked = 'standard_button_clicked',
  ExpertButtonClicked = 'expert_button_clicked',
  EnterpriseButtonClicked = 'enterprise_button_clicked',
  SubscriptionButtonClicked = 'subscription_button_clicked',
  YearlyButtonClicked = 'yearly_button_clicked',
  MonthlyButtonClicked = 'monthly_button_clicked',
  DownloadButtonClicked = 'download_button_clicked',
  FavoriteClicked = 'favorite_clicked',
  ProductSearch = 'product_search',
  HomepageSearchButtonClicked = 'homepage_search_button_clicked',
  HomepageTopSearchProduct = 'homepage_top_search_product',
  ContactButtonClicked = 'contact_button_clicked',
  AddToDownloadsClicked = 'add_to_downloads_clicked',
}

function getItem(clip: Clip) {
  const item: any = {
    quantity: 1,
    item_id: `SKU_${clip?.sku}`,
    item_name: clip?.title,
    affiliation: 'BambooStock',
    item_brand: 'BambooStock',
    price: clip?.price ?? 0,
    item_category: 'video',
  };

  return item;
}

function getCartItem(prodID: string, cart: Cart) {
  const prod = cart.cartProducts.find((cp) => cp.prodID === prodID);
  const variations = prod?.variations ?? [];
  const price = prod?.price ?? 0;
  const item: any = {
    quantity: 1,
    item_list_id: cart.cartID,
    item_id: `SKU_${prod?.sku}`,
    item_name: prod?.title,
    affiliation: 'BambooStock',
    item_brand: 'BambooStock',
    price: calculateItemPrice(price, variations),
    item_category: 'video',
    coupon: (cart?.cartVouchers ?? []).map((v) => v.code).join(', '),
  };
  variations.forEach((v, i) => {
    item[`item_category${i + 2}`] = v.name;
  });

  return item;
}

function getCartItems(cart: Cart) {
  return (cart?.cartProducts ?? []).map((prod) => {
    const variations = prod?.variations ?? [];
    const price = prod?.price ?? 0;
    const item: any = {
      quantity: 1,
      item_list_id: cart.cartID,
      item_id: `SKU_${prod?.sku}`,
      item_name: prod?.title,
      affiliation: 'BambooStock',
      item_brand: 'BambooStock',
      price: calculateItemPrice(price, variations),
      item_category: 'video',
      coupon: (cart?.cartVouchers ?? []).map((v) => v.code).join(', '),
    };
    variations.forEach((v, i) => {
      item[`item_category${i + 2}`] = v.name;
    });
    return item;
  });
}

function getCheckoutItems(checkout: Checkout) {
  return (checkout?.cartProducts ?? []).map((cp) => {
    const variations = cp?.variations ?? [];
    const price = cp?.price ?? 0;
    const item: any = {
      quantity: 1,
      item_list_id: checkout.cartID,
      item_id: `SKU_${cp.sku}`,
      item_name: cp.title,
      affiliation: 'BambooStock',
      item_brand: 'BambooStock',
      price: calculateItemPrice(price, variations),
      item_category: 'video',
      coupon: (checkout?.cartVouchers ?? []).map((v) => v.code).join(', '),
    };
    variations.forEach((v, i) => {
      item[`item_category${i + 2}`] = v.name;
    });
    return item;
  });
}

export function addToWishlist(clip: Clip) {
  const item = getItem(clip);
  sendGTMEvent({
    event: GTMEvents.ADDToWishlist,
    currency: BrazilianCurrency,
    value: item.price,
    items: [item],
  });
}

export function viewItemList(id: string, name: string, clips: Clip[]) {
  sendGTMEvent({
    event: GTMEvents.ViewItemList,
    item_list_id: id,
    item_list_name: name,
    items: clips.map((c) => getItem(c)),
  });
}

export function viewItem(clip: Clip) {
  sendGTMEvent({
    event: GTMEvents.ViewItem,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      product_id: clip.prodID,
      product_sku: clip.sku,
      product_title: clip.title,
      product_slug: clip.slug,
      product_maker_slug: clip.makerSlug,
    },
    event_source_url: window.location.href || undefined,
  });
}

export function selectItem(id: string, name: string, clip: Clip) {
  sendGTMEvent({
    event: GTMEvents.SelectItem,
    item_list_id: id,
    item_list_name: name,
    items: [getItem(clip)],
  });
}

export function addToCart(prodID: string, cart: Cart) {
  let total = 0;
  (cart?.cartProducts ?? []).forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  sendGTMEvent({
    event: GTMEvents.ADDToCart,
    currency: BrazilianCurrency,
    value: total,
    items: [getCartItem(prodID, cart)],
  });
}

export function removeFromCart(prodID: string, cart: Cart) {
  let total = 0;
  (cart?.cartProducts ?? []).forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  sendGTMEvent({
    event: GTMEvents.RemoveFromCart,
    currency: BrazilianCurrency,
    value: total,
    items: [getCartItem(prodID, cart)],
  });
}

export function viewCart(cart: Cart) {
  let total = 0;
  const cartProducts = cart?.cartProducts ?? [];
  const cartVouchers = cart?.cartVouchers ?? [];
  cartProducts.forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  if (cartVouchers.length > 0) {
    total = calculateDiscounts(total, cartVouchers);
  }

  sendGTMEvent({
    event: GTMEvents.ViewCart,
    currency: BrazilianCurrency,
    value: total,
    items: getCartItems(cart),
  });
}

export function beginCheckout(cart: Cart) {
  let total = 0;
  const cartProducts = cart?.cartProducts ?? [];
  const cartVouchers = cart?.cartVouchers ?? [];
  cartProducts.forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  if (cartVouchers.length > 0) {
    total = calculateDiscounts(total, cartVouchers);
  }

  sendGTMEvent({
    event: GTMEvents.BeginCheckout,
    value: total,
    currency: BrazilianCurrency,
    coupon: cartVouchers.map((v) => v.code).join(', '),
    items: getCartItems(cart),
  });
}

export function purchase(checkout: Checkout) {
  let total = 0;
  const cartProducts = checkout?.cartProducts ?? [];
  const cartVouchers = checkout?.cartVouchers ?? [];
  cartProducts.forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  if (cartVouchers.length > 0) {
    total = calculateDiscounts(total, cartVouchers);
  }

  sendGTMEvent({
    event: GTMEvents.Purchase,
    value: total,
    currency: BrazilianCurrency,
    transaction_id: checkout.checID,
    coupon: cartVouchers.map((v) => v.code).join(', '),
    items: getCheckoutItems(checkout),
  });
}

function getUserData() {
  try {
    const userData = getCookie('userData');
    return userData ? JSON.parse(userData) : undefined;
  } catch (e) {
    return undefined;
  }
}

export async function eventCreateAccount(email: string) {
  sendGTMEvent({
    event: GTMEvents.CreateAccount,
    event_time: Date.now(),
    user_data: {
      email: await encryptSHA256(email),
    },
    event_source_url: window.location.href || undefined,
  });
}
export async function eventInitiatedCheckout(plan: {
  planLevel: number;
  planName: string;
}) {
  sendGTMEvent({
    event: GTMEvents.InitiatedCheckout,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      plan_level: plan.planLevel,
      plan_name: plan.planName,
    },
    event_source_url: window.location.href || undefined,
  });
}

export async function eventAddPaymentMethod(data: { cardBrand: string }) {
  sendGTMEvent({
    event: GTMEvents.AddPaymentMethod,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      card_brand: data.cardBrand,
    },
    event_source_url: window.location.href || undefined,
  });
}

export async function eventPurchaseCompleted(data: {
  planLevel: number;
  planName: string;
  planPrice: number;
  planAnnual: boolean;
  planId: number;
}) {
  sendGTMEvent({
    event: GTMEvents.PurchaseCompleted,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      plan_id: data.planId,
      plan_name: data.planName,
      plan_price: data.planPrice,
      plan_type: data.planAnnual ? 'annual' : 'monthly',
      plan_level: data.planLevel,
      currency: BrazilianCurrency,
      payment_method: 'credit_card',
    },
    event_source_url: window.location.href || undefined,
  });
}
export async function eventHomeView() {
  sendGTMEvent({
    event: GTMEvents.HomeView,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventPlaylistsPageView() {
  sendGTMEvent({
    event: GTMEvents.PlaylistsPageView,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventSearchPageView() {
  sendGTMEvent({
    event: GTMEvents.SearchPageView,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventPlansPageView() {
  sendGTMEvent({
    event: GTMEvents.PlansPageView,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventHowItWorksPageView() {
  sendGTMEvent({
    event: GTMEvents.HowItWorksPageView,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventStudiosPageView() {
  sendGTMEvent({
    event: GTMEvents.StudiosPageView,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventFaqPageView() {
  sendGTMEvent({
    event: GTMEvents.FaqPageView,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventPlaylistViewed(playlist: {
  id: number;
  name: string;
  slug: string;
}) {
  sendGTMEvent({
    event: GTMEvents.PlaylistViewed,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      playlist_id: playlist.id,
      playlist_name: playlist.name,
      playlist_slug: playlist.slug,
    },
    event_source_url: window.location.href || undefined,
  });
}

export function eventPlanButtonClicked(planLevel: number, planId: number) {
  eventSubscriptionButtonClicked({ planId, planLevel });
  switch (planLevel) {
    case 1:
      eventBasicButtonClicked(planId);
      break;
    case 2:
      eventStandardButtonClicked(planId);
      break;
    case 3:
      eventExpertButtonClicked(planId);
      break;
    case 4:
      eventEnterpriseButtonClicked(planId);
      break;
  }
}

export async function eventBasicButtonClicked(planId: number) {
  sendGTMEvent({
    event: GTMEvents.BasicButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      plan_id: planId,
    },
    event_source_url: window.location.href || undefined,
  });
}
export async function eventStandardButtonClicked(planId: number) {
  sendGTMEvent({
    event: GTMEvents.StandardButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      plan_id: planId,
    },
    event_source_url: window.location.href || undefined,
  });
}
export async function eventExpertButtonClicked(planId: number) {
  sendGTMEvent({
    event: GTMEvents.ExpertButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      plan_id: planId,
    },
    event_source_url: window.location.href || undefined,
  });
}
export async function eventEnterpriseButtonClicked(planId: number) {
  sendGTMEvent({
    event: GTMEvents.EnterpriseButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      plan_id: planId,
    },
    event_source_url: window.location.href || undefined,
  });
}

export async function eventProductSearch(term: string) {
  sendGTMEvent({
    event: GTMEvents.ProductSearch,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      search_term: term,
    },
    event_source_url: window.location.href || undefined,
  });
}
export async function eventHomepageSearchButtonClicked(term: string) {
  sendGTMEvent({
    event: GTMEvents.HomepageSearchButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      search_term: term,
    },
    event_source_url: window.location.href || undefined,
  });
}
export async function eventHomepageTopSearchProduct(term: string) {
  sendGTMEvent({
    event: GTMEvents.HomepageTopSearchProduct,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      search_term: term,
    },
    event_source_url: window.location.href || undefined,
  });
}
export async function eventContactButtonClicked() {
  sendGTMEvent({
    event: GTMEvents.ContactButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventDownloadButtonClicked(clip: {
  id: string;
  slug: string;
  sku: string;
  title: string;
  makerSlug: string;
}) {
  sendGTMEvent({
    event: GTMEvents.DownloadButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      product_id: clip.id,
      product_sku: clip.sku,
      product_title: clip.title,
      product_slug: clip.slug,
      product_maker_slug: clip.makerSlug,
    },
    event_source_url: window.location.href || undefined,
  });
}
export async function eventFavoriteClicked(clip: {
  id: string;
  slug: string;
  sku: string;
  title: string;
  makerSlug: string;
}) {
  sendGTMEvent({
    event: GTMEvents.FavoriteClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      product_id: clip.id,
      product_sku: clip.sku,
      product_title: clip.title,
      product_slug: clip.slug,
      product_maker_slug: clip.makerSlug,
    },
    event_source_url: window.location.href || undefined,
  });
}

export async function eventSubscriptionButtonClicked(data: {
  planLevel: number;
  planId: number;
}) {
  sendGTMEvent({
    event: GTMEvents.SubscriptionButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      plan_id: data.planId,
      plan_level: data.planLevel,
    },
    event_source_url: window.location.href || undefined,
  });
}

export function eventPeriodButtonClicked(period: 'yearly' | 'monthly') {
  switch (period) {
    case 'yearly':
      eventYearlyButtonClicked();
      break;
    case 'monthly':
      eventMonthlyButtonClicked();
      break;
  }
}

export async function eventYearlyButtonClicked() {
  sendGTMEvent({
    event: GTMEvents.YearlyButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventMonthlyButtonClicked() {
  sendGTMEvent({
    event: GTMEvents.MonthlyButtonClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    event_source_url: window.location.href || undefined,
  });
}

export async function eventAddToDownloadsClicked(
  clips: {
    id: string;
    sku: string;
    title: string;
  }[]
) {
  sendGTMEvent({
    event: GTMEvents.AddToDownloadsClicked,
    event_time: Date.now(),
    user_data: getUserData(),
    custom_data: {
      products: clips.map((clip) => ({
        product_id: clip.id,
        product_sku: clip.sku,
        product_title: clip.title,
      })),
    },
    event_source_url: window.location.href || undefined,
  });
}
