import Documents from './Documents';
import Headset from './Headset';
import MicrosoftWord from './MicrosoftWord';
import PhotoVideo from './PhotoVideo';
import User from './User';
import Heart from './Heart';
import DownloadIcon from './DownloadIcon';
import Cart from './Cart';
import Emoji from './Emoji';
import Google from './Google';
import Percent from './Percent';
import BRL from './BRL';
import Menu from './Menu';
import MenuBold from './MenuBold';

export default {
  Documents,
  Headset,
  MicrosoftWord,
  PhotoVideo,
  User,
  Heart,
  DownloadIcon,
  Cart,
  Emoji,
  Google,
  Percent,
  BRL,
  Menu,
  MenuBold,
};
