import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';
import { Term } from '../api/types';

type InitialState = {
  termByID: { [termID: number]: Term | undefined };
  termBySlug: { [slug: string]: number | undefined };
};

const initialData: InitialState = {
  termByID: {},
  termBySlug: {},
};

let store = createStore<InitialState>(() => initialData);

export const setTerm = (term: Term) => {
  let termByID = store.getState().termByID;
  let termBySlug = store.getState().termBySlug;
  termByID[term.termID] = term;
  termBySlug[term.slug] = term.termID;

  store.setState({ termByID, termBySlug });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function state() {
  return store.getState();
}

export const useTerm = () => useStore(store, (s) => s);
